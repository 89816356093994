import React, { useState } from 'react';
import {
  Container, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField, MenuItem, Select
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { SaveAlt as ExportExcelIcon, PictureAsPdf as ExportPDFIcon } from '@mui/icons-material';

const claymorphismStyle = {
  boxShadow: '9px 9px 16px rgba(0, 0, 0, 0.2), -9px -9px 16px rgba(255, 255, 255, 0.8)',
  background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
  borderRadius: '20px',
  padding: '20px',
};

const initialData = [
  { productName: 'phone', category: 'Electronics', opening: 2, inward: 2, outward: 1, unit: 'Pcs', closing: 1 },
  // Add more products here if needed
];

const ItemProgress = () => {
  const [data, setData] = useState(initialData);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ItemProgress');
    XLSX.writeFile(workbook, 'ItemProgress.xlsx');
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Item Progress Report', 20, 20);
    data.forEach((item, index) => {
      doc.text(`${item.productName}: ${item.category}, Opening: ${item.opening}, Closing: ${item.closing}`, 20, 30 + index * 10);
    });
    doc.save('ItemProgress.pdf');
  };

  return (
    <Container>
      <Paper style={claymorphismStyle}>
        {/* Export Buttons at the top */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <Button variant="contained" onClick={exportExcel} startIcon={<ExportExcelIcon />} style={{ marginRight: '10px' }}>
            Export Excel
          </Button>
          <Button variant="contained" onClick={exportPDF} startIcon={<ExportPDFIcon />}>
            Export PDF
          </Button>
        </div>

        {selectedProduct ? (
          <>
            <Typography variant="h5">Item Ledger</Typography>
            <Button onClick={() => setSelectedProduct(null)}>Back</Button>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Select
                value={selectedProduct.productName}
                onChange={(e) => setSelectedProduct(data.find(item => item.productName === e.target.value))}
                fullWidth
                variant="outlined"
              >
                {data.map((product) => (
                  <MenuItem key={product.productName} value={product.productName}>
                    {product.productName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Voucher</TableCell>
                  <TableCell>Party</TableCell>
                  <TableCell>Inward</TableCell>
                  <TableCell>Outward</TableCell>
                  <TableCell>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>INV001</TableCell>
                  <TableCell>{selectedDate.format('DD-MMM-YYYY')}</TableCell>
                  <TableCell>Sale Invoice</TableCell>
                  <TableCell>Demo Customer</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <>
            <Typography variant="h5">Item Progress</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Opening</TableCell>
                  <TableCell>Inward</TableCell>
                  <TableCell>Outward</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Closing</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.productName} onClick={() => handleProductClick(row)}>
                    <TableCell>{row.productName}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.opening}</TableCell>
                    <TableCell>{row.inward}</TableCell>
                    <TableCell>{row.outward}</TableCell>
                    <TableCell>{row.unit}</TableCell>
                    <TableCell>{row.closing}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default ItemProgress;
