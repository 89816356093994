// BankNames.js
const BankNames = [
    'State Bank of India',
    'HDFC Bank',
    'ICICI Bank',
    'Axis Bank',
    'Punjab National Bank',
    'Bank of Baroda',
    'Canara Bank',
    'Union Bank of India',
    'Kotak Mahindra Bank',
    'Bank of India',
    'Indian Bank',
    'Central Bank of India',
    'IDFC FIRST Bank',
    'Yes Bank',
    'IndusInd Bank',
    'HSBC India',
    'Standard Chartered Bank',
    'JPMorgan Chase Bank',
    'Citibank India',
    'RBL Bank',
    'UCO Bank',
    'Indian Overseas Bank',
    'Bank of Maharashtra',
    'Syndicate Bank',
    'Oriental Bank of Commerce',
    'Dena Bank',
    'Vijaya Bank',
    'Abu Dhabi Commercial Bank',
    'Karnataka Bank',
    'Lakshmi Vilas Bank',
    'Tamilnad Mercantile Bank',
    'South Indian Bank',
    'Federal Bank',
    'Yes Bank',
    'Post Office Savings Bank',
    'Jammu & Kashmir Bank',
    'Punjab & Sind Bank',
    'Himachal Pradesh State Cooperative Bank',
    'Nagaland State Cooperative Bank',
    'Mizoram Cooperative Bank',
    'Sikkim State Cooperative Bank',
    'Tripura State Cooperative Bank',
    'Assam Cooperative Bank',
    'Gujarat State Cooperative Bank',
    'Maharashtra State Cooperative Bank',
    'Rajasthan State Cooperative Bank',
    'Kolkata Metro Bank',
    'Dhanlaxmi Bank',
    'Kangra Central Cooperative Bank',
    'Jammu & Kashmir Cooperative Bank',
    'Arunachal Pradesh State Cooperative Bank',
    'Andhra Pradesh State Cooperative Bank',
    'Uttar Pradesh State Cooperative Bank',
    'Jharkhand State Cooperative Bank',
    'Chhattisgarh State Cooperative Bank',
    'Haryana State Cooperative Bank',
    'Bihar State Cooperative Bank',
    'Odisha State Cooperative Bank',
    'Kerala State Cooperative Bank'
    // Add more banks as needed
  ];
  
  export default BankNames;
  