import React, { useState } from 'react';
import BusinessNav from './BusinessNav';
import BusinessSidebar from './BusinessSidebar';
import BusinessDashboard from './Reports/BusinessDashboard';
import ReportList from './Reports/ReportList';
import Customer from '../Customer/Customer';
import Expense from '../Expense/Expense';
import Master from '../Master/Master';
import Sale from '../Sale/Sale';
import Staff from '../Staff/Staff';
import Purchase from '../Purchase/Purchase';
import Inventory from './Inventory';
import Service from '../Service/Service';

const Dashboard = () => {
  const [activeNav, setActiveNav] = useState('Actions'); // Track the active nav item
  const [activeTab, setActiveTab] = useState('BusinessDashboard'); // Track the active sidebar item

  const renderContent = () => {
    switch (activeTab) {
      case 'BusinessDashboard':
        return <BusinessDashboard />;
      case 'Sale':
        return <Sale />;
      case 'Purchase':
        return <Purchase />;
      case 'customer':
        return <Customer />;
      case 'Expense':
        return <Expense />;
      case 'Staff':
        return <Staff />;
      case 'Inventory':
        return <Inventory />;
      case 'Service':
        return <Service />;
      case 'Masters':
        return <Master/>;
      // case 'Transactions':
      //   return <TransactionsContent />;
     
      case 'ReportList':
        return <ReportList />;
      default:
        return <BusinessDashboard />;
    }
  };

  return (
    <div className="dashboard-container">
      <BusinessNav setActiveNav={setActiveNav} /> {/* Top nav to control the sidebar */}
      <div className="content-area">
        <BusinessSidebar
          activeNav={activeNav} // Pass the active nav item to control sidebar items
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
        <div className="main-content">
          {renderContent()}
        </div>
      </div>

      <style jsx>{`
        .dashboard-container {
          display: flex;
          flex-direction: column;
          height: 100vh;
        }
        
        .content-area {
          display: flex;
          flex: 1;
        }
        
        .main-content {
          flex: 1;
          padding: 20px;
          display: flex;
          justify-content: flex-start;
          width: calc(100% - 250px); /* Adjust based on sidebar width */
        }
      `}</style>
    </div>
  );
};

export default Dashboard;
