import React, { useState } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PrintIcon from '@mui/icons-material/Print';
import { styled } from '@mui/material/styles';

// Styled component for Claymorphism effect
const ClaymorphicBox = styled(Box)(({ theme }) => ({
  background: '#e0e5ec',
  boxShadow: '8px 8px 16px #a3b1c6, -8px -8px 16px #ffffff',
  borderRadius: '15px',
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
}));

const CollectionSummary = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const data = [
    { date: '01-Aug-2024', number: 1, voucher: 'V001', party: 'ABC Corp', type: 'Credit', accountName: 'Cash', amount: 1000 },
    { date: '02-Aug-2024', number: 2, voucher: 'V002', party: 'XYZ Ltd', type: 'Debit', accountName: 'Bank', amount: 2000 },
    // Add more data here
  ];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Collection Summary");
    XLSX.writeFile(workbook, "CollectionSummary.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Collection Summary", 20, 10);
    doc.autoTable({
      head: [['Date', 'Number', 'Voucher', 'Party', 'Type', 'Account Name', 'Amount']],
      body: data.map(row => [row.date, row.number, row.voucher, row.party, row.type, row.accountName, row.amount]),
    });
    doc.save('CollectionSummary.pdf');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ClaymorphicBox>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
              Collection Summary
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body2" color="textSecondary" sx={{ textAlign: { xs: 'left', md: 'center' }, fontSize: { xs: '0.75rem', md: '1rem' } }}>
              Period: 01-Aug-2024 To 31-Aug-2024
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent={{ xs: 'flex-start', md: 'flex-end' }} alignItems="center" flexWrap="wrap">
              <Button variant="contained" color="primary" sx={{ ml: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 }, width: { xs: '100%', sm: 'auto' } }} onClick={exportToExcel}>
                Export Excel
              </Button>
              <Button variant="contained" color="primary" sx={{ ml: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 }, width: { xs: '100%', sm: 'auto' } }} onClick={exportToPDF}>
                Export PDF
              </Button>
              <DatePicker
                views={['year', 'month', 'day']} 
                label="Select Date"
                minDate={new Date('2020-01-01')}
                maxDate={new Date('2030-12-31')}
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                }}
                renderInput={(params) => (
                  <Button {...params} variant="contained" color="primary" sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', sm: 'auto' } }}>
                    {params.inputProps.value}
                  </Button>
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ mt: 3, background: '#ffffff', boxShadow: 'none', borderRadius: '15px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Voucher</TableCell>
                <TableCell>Party</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Account Name</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.number}</TableCell>
                  <TableCell>{row.voucher}</TableCell>
                  <TableCell>{row.party}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.accountName}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ClaymorphicBox>
    </LocalizationProvider>
  );
};

export default CollectionSummary;
