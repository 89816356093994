

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { Add, Edit, Delete, Save, Search } from '@mui/icons-material';
import PurchaseBill from './PurchaseBill'; // Ensure to create a PurchaseBillForm component

const PurchaseBillTable = () => {
  const [bills, setBills] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBill, setSelectedBill] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  
    useEffect(() => {
      const savedBills = JSON.parse(localStorage.getItem('purchaseBills')) || [];
      
      // Set default value if there are no bills saved
      if (savedBills.length === 0) {
        const defaultBill = {
          purchaseBillNo: 'PB001',
          supplierName: 'Default Supplier',
          billDate: '2024-08-31',
          items: [
            {
              itemName: 'Default Item',
              quantity: 10,
              unit: 'kg',
              price: 50,
              discount: 5,
              tax: 18,
              cess: 2,
              amount: 500,
            },
          ],
        };
        savedBills.push(defaultBill);
        localStorage.setItem('purchaseBills', JSON.stringify(savedBills));
      }
    
      console.log('Retrieved bills:', savedBills); // Log retrieved data
      setBills(savedBills);
    }, []);
    

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAdd = () => {
    setSelectedBill(null);
    setOpenForm(true);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setSelectedBill(bills[index]);
    setOpenForm(true);
  };

  const handleSave = (billData) => {
    const updatedBills = [...bills];
    if (editIndex !== null) {
      updatedBills[editIndex] = billData;
    } else {
      updatedBills.push(billData);
    }
    console.log('Saving bills:', updatedBills); // Log data being saved
    localStorage.setItem('purchaseBills', JSON.stringify(updatedBills));
    setBills(updatedBills);
    setOpenForm(false);
    setEditIndex(null);
  };

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const updatedBills = bills.filter((_, i) => i !== deleteIndex);
      setBills(updatedBills);
      localStorage.setItem('purchaseBills', JSON.stringify(updatedBills));
    }
    handleDeleteClose();
  };

  const filteredBills = bills.filter(bill =>
    bill.purchaseBillNo.includes(searchTerm) ||
    bill.supplierName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log('Filtered Bills:', filteredBills); // Log filtered data

  return (
    <Box sx={{ padding: '2rem', overflowX: 'auto' }}>
 <Box display="flex"  alignItems="flex-start"justifyContent='space-between' mb={3}>
  <Box  mb={1} sx={{ width: '100%', maxWidth: '300px' }}>
    <TextField
      label="Search Purchase Bills"
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={handleSearchChange}
      InputProps={{
        endAdornment: (
          <IconButton>
            <Search />
          </IconButton>
        ),
      }}
      sx={{ width: '100%' }}
    />
  </Box>
  <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAdd}>
    Add Purchase Bill
  </Button>
</Box>


      <TableContainer component={Paper} sx={{ height: '160px', overflowY: 'auto', width: '100%' }}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Purchase Bill No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Supplier Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Bill Date</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Item Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Quantity</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Unit</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Price/Unit</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Discount (%)</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Tax (%)</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Cess (%)</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Amount</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBills.length === 0 ? (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  No purchase bills available
                </TableCell>
              </TableRow>
            ) : (
              filteredBills.flatMap((bill, billIndex) =>
                bill.items.map((item, itemIndex) => (
                  <TableRow key={`${billIndex}-${itemIndex}`}>
                    <TableCell>{bill.purchaseBillNo}</TableCell>
                    <TableCell>{bill.supplierName}</TableCell>
                    <TableCell>{bill.billDate}</TableCell>
                    <TableCell>{item.itemName}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>{item.discount}</TableCell>
                    <TableCell>{item.tax}</TableCell>
                    <TableCell>{item.cess}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleEdit(billIndex)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteOpen(billIndex)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={openDialog}
        onClose={handleDeleteClose}
        aria-labelledby="delete-confirmation-dialog"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this purchase bill?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Purchase Bill Form Modal */}
      <Dialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="purchase-bill-form-dialog"
      >
        <DialogContent>
          <PurchaseBill
            purchaseBill={selectedBill}
            onSave={handleSave}
            onClose={() => setOpenForm(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PurchaseBillTable;

