import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Snackbar,
  Alert,
  Typography,
} from '@mui/material';
import { CurrencyRupee, Percent } from '@mui/icons-material';

const ServiceForm = () => {
  // State hooks for form fields
  const [serviceName, setServiceName] = useState('');
  const [product, setProduct] = useState('');
  const [itemCode, setItemCode] = useState('');
  const [printName, setPrintName] = useState('');
  const [category, setCategory] = useState('');
  const [hsnSac, setHsnSac] = useState('');
  const [cgst, setCgst] = useState('');
  const [sgst, setSgst] = useState('');
  const [igst, setIgst] = useState('');
  const [cess, setCess] = useState('');
  const [serviceRate, setServiceRate] = useState('');
  const [minservicerate, setMinServiceRate] = useState(' ');
  const [description, setDescription] = useState('');
  const [gstRate, setGstRate] = useState('');
  const [discountPercent, setDiscountPercent] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const maxDescriptionLength = 250;

  const [successMessage, setSuccessMessage] = useState('');

  // State hooks for customer details
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [serviceStart, setServiceStart] = useState('');
  const [serviceEnd, setServiceEnd] = useState('');

  const handleSave = () => {
    if (!serviceName || !category || !serviceRate || !customerName || !customerPhone || !customerAddress || !printName || !igst ) {
      alert("Please fill all required fields.");
      return;
    }

    const service = {
      serviceName,
      product,
      itemCode,
      printName,
      category,
      hsnSac,
      cgst,
      sgst,
      igst,
      cess,
      serviceRate,
      minservicerate,
      description,
      gstRate,
      discountPercent,
      serviceDescription,
      customerName,
      customerPhone,
      customerEmail,
      customerAddress,
      serviceStart,
      serviceEnd,
    };

    const storedServices = JSON.parse(localStorage.getItem('services')) || [];
    storedServices.push(service);
    localStorage.setItem('services', JSON.stringify(storedServices));

    // Reset form fields after save
    setServiceName('');
    setProduct('');
    setItemCode('');
    setPrintName('');
    setCategory('');
    setHsnSac('');
    setCgst('');
    setSgst('');
    setIgst('');
    setCess('');
    setServiceRate('');
    setDescription('');
    setGstRate('');
    setMinServiceRate(' ');
    setDiscountPercent('');
    setServiceDescription('');
    setCustomerName('');
    setCustomerPhone('');
    setCustomerEmail('');
    setCustomerAddress('');
    setServiceStart('');
    setServiceEnd('');

    // Show success message
    setSuccessMessage('Service saved successfully!');
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  const handleCgstChange = (e) => {
    const value = e.target.value;
    setCgst(value);
    const parsedValue = parseFloat(value) || 0;
    setSgst(parsedValue.toFixed(2));
    setIgst((parsedValue * 2 + (parseFloat(cess) || 0)).toFixed(2));
  };

  const handleCessChange = (e) => {
    const value = e.target.value;
    setCess(value);
    const parsedCess = parseFloat(value) || 0;
    setIgst((parseFloat(cgst) * 2 + parsedCess).toFixed(2));
  };

  const handleDescriptionChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= maxDescriptionLength) {
      setServiceDescription(newValue);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '1800px',
        // margin: '1rem auto',
        padding: '20px',
        backgroundColor: '#f1f1f1',
        // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        boxShadow:'10',
        borderRadius: '10px',
        fontFamily: 'Poppins',
        marginTop:'-15px',
        marginLeft:'-100px',
        marginRight:'-150px'
      }}
    >
      <form>
        <Grid container spacing={4}>
          {/* Customer Details Section */}
          <Grid item xs={12} md={4}>
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Customer Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Customer Name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Phone No."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    required
                    type="tel"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    type="email"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={customerAddress}
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" gap={2}>
                    <TextField
                      label="Service Start Date"
                      variant="outlined"
                      size="small"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={serviceStart}
                      onChange={(e) => setServiceStart(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label="Service End Date"
                      variant="outlined"
                      size="small"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={serviceEnd}
                      onChange={(e) => setServiceEnd(e.target.value)}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </fieldset>

            {/* Other Details Section */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginTop: '1rem',
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Other Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Sale Discount"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={discountPercent}
                    onChange={(e) => setDiscountPercent(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Percent />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>

          {/* Service Details Section */}
          <Grid item xs={12} md={4}>
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginLeft:'60px',
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Service Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Product"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={product}
                    onChange={(e) => setProduct(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      label="Category"
                      required
                    >
                      <MenuItem value="">
                        <em>Select Category</em>
                      </MenuItem>
                      <MenuItem value="Category 1">Category 1</MenuItem>
                      <MenuItem value="Category 2">Category 2</MenuItem>
                      <MenuItem value="Category 3">Category 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Item Code"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={itemCode}
                    onChange={(e) => setItemCode(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Service Name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Print Name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={printName}
                    onChange={(e) => setPrintName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="HSN/SAC"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={hsnSac}
                    onChange={(e) => setHsnSac(e.target.value)}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>

          {/* Service Rate and Description Section */}
          <Grid item xs={12} md={4}>
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Service Rate & Description
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Service Charge"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={serviceRate}
                    onChange={(e) => setServiceRate(e.target.value)}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Min. Service Charge"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={serviceRate}
                    onChange={(e) => setMinServiceRate(e.target.value)}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Service Description"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={serviceDescription}
                    onChange={handleDescriptionChange}
                    multiline
                    rows={3}
                    inputProps={{ maxLength: maxDescriptionLength }}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginTop: '0.5rem' }}
                  >
                    {serviceDescription.length}/{maxDescriptionLength} characters used
                  </Typography>
                </Grid>
              </Grid>
            </fieldset>

            {/* Tax Details Section */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginTop: '1rem',
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Tax Details
              </legend>
              <Grid container spacing={2}>
                {/* GST Rate Label */}
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: '#333',
                    }}
                  >
                    GST Rate (%)
                  </Typography>
                </Grid>

                {/* First Row: CGST and Cess */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="CGST (%)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={cgst}
                    onChange={handleCgstChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Percent />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Cess (%)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={cess}
                    onChange={handleCessChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Percent />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Second Row: SGST and IGST */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="SGST (%)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={sgst}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Percent />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="IGST (%)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={igst}
                    required
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Percent />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
        </Grid>

        {/* Save Button */}
        <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  padding: '8px 20px',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: '#0075fd',
                  borderRadius: '10px',
                  boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
                  float: 'right',
                  marginTop: '10px',
                }}
              >
                Save
              </Button>
            </Box>
      </form>

      {/* Snackbar for Success Message */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ServiceForm;