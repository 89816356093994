import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { Person, UploadFile, Cancel, CurrencyRupee } from '@mui/icons-material';
import { states } from '../BusinessProfile/States';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomerForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState(null);
  const [fullName, setFullName] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [country, setCountry] = useState('India');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [panNo, setPanNo] = useState('');
  const [gstNo, setGstNo] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [openingBalance, setOpeningBalance] = useState('');
  const [creditLimit, setCreditLimit] = useState('');
  const [creditAllowed, setCreditAllowed] = useState('no');
  const [accountType, setAccountType] = useState('debit');
  const [editIndex, setEditIndex] = useState(null);
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message

  useEffect(() => {
    if (location.state && location.state.customer) {
      const customer = location.state.customer;
      setFullName(customer.fullName || '');
      setBillingAddress(customer.billingAddress || '');
      setCity(customer.city || '');
      setState(customer.state || '');
      setPinCode(customer.pinCode || '');
      setCountry(customer.country || 'India');
      setEmail(customer.email || '');
      setPhoneNo(customer.phoneNo || '');
      setContactNo(customer.contactNo || '');
      setPanNo(customer.panNo || '');
      setGstNo(customer.gstNo || '');
      setDocumentType(customer.documentType || '');
      setDocumentNumber(customer.documentNumber || '');
      setOpeningBalance(customer.openingBalance || '');
      setCreditLimit(customer.creditLimit || '');
      setCreditAllowed(customer.creditAllowed || 'no');
      setAccountType(customer.accountType || 'debit');
      setProfilePic(customer.profilePic || null);
      setEditIndex(location.state.editIndex);
    }
  }, [location.state]);

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setProfilePic(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveProfilePic = () => {
    setProfilePic(null);
  };

  const handleSave = () => {

    if (!fullName || !billingAddress || !state || !contactNo  ) {
      alert("Please fill all required fields.");
      return;
    }

    const customer = {
      fullName,
      billingAddress,
      city,
      state,
      pinCode,
      country,
      email,
      phoneNo,
      contactNo,
      panNo,
      gstNo,
      documentType,
      documentNumber,
      openingBalance,
      creditLimit,
      creditAllowed,
      accountType,
      profilePic,
    };

    const storedCustomers = JSON.parse(localStorage.getItem('customers')) || [];

    if (editIndex !== null) {
      storedCustomers[editIndex] = customer;
    } else {
      storedCustomers.push(customer);
    }

    localStorage.setItem('customers', JSON.stringify(storedCustomers));

    // Reset form fields after save
    setFullName('');
    setBillingAddress('');
    setCity('');
    setState('');
    setPinCode('');
    setCountry('India');
    setEmail('');
    setPhoneNo('');
    setContactNo('');
    setPanNo('');
    setGstNo('');
    setDocumentType('');
    setDocumentNumber('');
    setOpeningBalance('');
    setCreditLimit('');
    setCreditAllowed('no');
    setAccountType('debit');
    setProfilePic(null);
    setEditIndex(null);

    // Show success message
    setSuccessMessage('Customer saved successfully!');
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: '-2px auto',
        padding: '1rem',
        backgroundColor: '#f9f9f9',
        boxShadow: 10,
        borderRadius: '8px',
        fontFamily: 'Poppins',
      }}
    >
      <form>
        <Grid container spacing={4}>
          {/* First Column: Profile Picture */}
          <Grid item xs={12} md={3}>
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '.5rem',
                borderRadius: '8px',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                  fontFamily: 'Poppins',
                }}
              >
                Customer Profile 
              </legend>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  sx={{
                    width: 90,
                    height: 90,
                    border: '2px dashed #ccc',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    marginBottom: '1rem',
                  }}
                >
                  {profilePic ? (
                    <img
                      src={profilePic}
                      alt="Profile"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Person sx={{ fontSize: 60, color: '#ccc' }} />
                  )}
                </Box>
                <Box display="flex" gap={1}>
                  <label htmlFor="upload-button">
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="upload-button"
                      type="file"
                      onChange={handleProfilePicChange}
                    />
                    <Button
                      variant="contained"
                      style={{ fontSize: '0.6rem' }}
                      component="span"
                      startIcon={<UploadFile />}
                    >
                      ADD
                    </Button>
                  </label>
                  {profilePic && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleRemoveProfilePic}
                      style={{ fontSize: '0.6rem' }}
                      startIcon={<Cancel />}
                    >
                      Clear
                    </Button>
                  )}
                </Box>
              </Box>
            </fieldset>
          </Grid>

          {/* Second Column: Client and Tax Details */}
          <Grid item xs={12} md={5}>
            {/* Client Details */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '8px',
                marginBottom: '1rem',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Client Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Full Name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Billing Address"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={billingAddress}
                    onChange={(e) => setBillingAddress(e.target.value)}
                    required
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    required
                  >
                    <InputLabel>State</InputLabel>
                    <Select
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      label="State"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {states.map((stateOption) => (
                        <MenuItem
                          key={stateOption.id}
                          value={stateOption.name}
                        >
                          {stateOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="PIN Code"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    required
                    type="number"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email ID"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone No"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    required
                    type="tel"
                  />
                </Grid>

                <Grid item xs={12} sm={6} sx={{marginTop: '1.5rem'}}>
                  <TextField
                    label="Contact No"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    type="tel"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                <label>Date of birth :</label>  
                <TextField
                  type="date"
                  fullWidth
                  variant="outlined"
                  size="small"
                  />
                </Grid>
              </Grid>
            </fieldset>

            {/* Tax Details */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '8px',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Tax Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="PAN No"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={panNo}
                    onChange={(e) => setPanNo(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="GSTIN"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={gstNo}
                    onChange={(e) => setGstNo(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>

          {/* Third Column: Identity, Account, and Notes Details */}
          <Grid item xs={12} md={4}>
            

            {/* Account Details */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '8px',
                marginBottom: '2rem',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Account Details
              </legend>
              <Grid container spacing={2}>

              <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Account Type</InputLabel>
                    <Select
                      value={accountType}
                      onChange={(e) => setAccountType(e.target.value)}
                      label="Account Type"
                    >
                      <MenuItem value="debit">Debit</MenuItem>
                      <MenuItem value="credit">Credit</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Opening Balance"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={openingBalance}
                    onChange={(e) => setOpeningBalance(e.target.value)}
                    required
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

              </Grid>
            </fieldset>

            {/* Identity Details */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '8px',
                marginBottom: '2rem',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Identity Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>Document Type</InputLabel>
                    <Select
                      value={documentType}
                      onChange={(e) => setDocumentType(e.target.value)}
                      label="Document Type"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Aadhar">Aadhar</MenuItem>
                      <MenuItem value="PAN Card">PAN Card</MenuItem>
                      <MenuItem value="License">License</MenuItem>
                      <MenuItem value="Voter ID">Voter ID</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Document Number"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={documentNumber}
                    onChange={(e) => setDocumentNumber(e.target.value)}
                    disabled={!documentType}
                    required={!!documentType}
                  />
                </Grid>
              </Grid>
            </fieldset>

            {/* Other Details */}
          <fieldset
            style={{
              border: '1px solid #bfbfbf',
              padding: '1.5rem',
              borderRadius: '8px',
              backgroundColor: '#fff'
            }}
          >
            <legend
              style={{
                padding: '0 0.5rem',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              Other Details
            </legend>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography component="legend" variant="subtitle2">
                    Credit Allowed
                  </Typography>
                  <RadioGroup
                    row
                    value={creditAllowed}
                    onChange={(e) => setCreditAllowed(e.target.value)}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Credit Limit"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={creditLimit}
                  onChange={(e) => setCreditLimit(e.target.value)}
                  disabled={creditAllowed === 'no'}
                  required={creditAllowed === 'yes'}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupee />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: '0.1rem' }}>
                <TextField
                  label="Remark/Note"
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
          </fieldset>

          </Grid>
        </Grid>

        {/* Save Button */}
        <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  padding: '8px 20px',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: '#0075fd',
                  borderRadius: '10px',
                  boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
                  float: 'right',
                  marginTop: '10px',
                }}
              >
                Save
              </Button>
            </Box>
      </form>

       {/* Success Message Snackbar */}
       <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomerForm;