import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InventoryTable from './InventoryTable';
import InventoryForm from './InventoryForm';

const Inventory = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  const getTitle = () => {
    switch (selectedOption) {
      case 'Add-inventory':
        return 'Add Inventory';
      case 'manage-inventory':
        return 'Search and Manage Inventory';
      default:
        return '';
    }
  };

  return (
    <div className="container">
      {!selectedOption ? (
        <div className="inventory-content">
          <div className="options">
            <div className="option-item" onClick={() => handleOptionClick('Add-inventory')}>
              <span>Add Inventory</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            <div className="option-item" onClick={() => handleOptionClick('manage-inventory')}>
              <span>Search and Manage Inventory</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
          </div>
        </div>
      ) : (
        <div className="output-container">
          <div className="header">
            <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
            <h2 className="page-title">{getTitle()}</h2>
          </div>
          <div className="output-content">
            {selectedOption === 'Add-inventory' && <InventoryForm /> }
            {selectedOption === 'manage-inventory' && <InventoryTable />}
          </div>
        </div>
      )}

      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          padding: 10px;
          align-items: stretch;
        }

        .inventory-content {
          font-family: 'Poppins', sans-serif;
          width: 30%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .option-item {
          padding: 12px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .option-item:hover {
          background-color: #f0f0f0;
        }

        .arrow-icon {
          font-size: 16px;
          color: #888;
        }

        .output-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .header {
          display: flex;
          align-items: center;
        }

        .back-icon {
          cursor: pointer;
          font-size: 22px;
        }

        .page-title {
          margin-left: 10px;
          font-size: 15px;
        }

        .output-content {
          flex-grow: 2;
        }

        /* Media queries for responsive design */
        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .inventory-content,
          .output-content {
            width: 100%;
            height: auto;
          }
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          .inventory-content {
            width: 40%;
          }

          .output-content {
            width: 60%;
          }
        }

        @media (min-width: 1025px) {
          .inventory-content {
            width: 25%;
          }

          .output-content {
            width: 75%;
          }
        }
      `}</style>
    </div>
  );
};

export default Inventory;
