import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaleForm from './SaleForm';
import SaleList from './SaleList';
// Import additional components for each case
// import SaleReturnForm from './SaleReturnForm';
// import QuotationForm from './QuotationForm';
// import DeliveryNoteForm from './DeliveryNoteForm';
// import ProformaInvoiceForm from './ProformaInvoiceForm';
// import SaleOrderForm from './SaleOrderForm';
// import CreditNoteForm from './CreditNoteForm';
// import DebitNoteForm from './DebitNoteForm';

const Sale = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [hoveredOption, setHoveredOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  const handleMouseEnter = (option) => {
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const renderPopupOptions = (option) => {
    switch (option) {
      case 'Invoice':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add Invoice')}>
              Add Invoice
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('Manage Invoice')}>
              Search and Manage Invoice
            </div>
          </div>
        );
      case 'Sale Return':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add Sale Return')}>
              Add Sale Return
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('Manage Sale Return')}>
              Search and Manage Sale Return
            </div>
          </div>
        );
      case 'Quotation':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add Quotation')}>
              Add Quotation
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('Manage Quotation')}>
              Search and Manage Quotation
            </div>
          </div>
        );
      case 'Delivery Note':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add Delivery Note')}>
              Add Delivery Note
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('Manage Delivery Note')}>
              Search and Manage Delivery Note
            </div>
          </div>
        );
      case 'Proforma Invoice':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add Proforma Invoice')}>
              Add Proforma Invoice
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('Manage Proforma Invoice')}>
              Search and Manage Proforma Invoice
            </div>
          </div>
        );
      case 'Sale Order':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add Sale Order')}>
              Add Sale Order
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('Manage Sale Order')}>
              Search and Manage Sale Order
            </div>
          </div>
        );
      // case 'Credit Note':
      //   return (
      //     <div className="popup-options">
      //       <div className="popup-option-item" onClick={() => handleOptionClick('Add Credit Note')}>
      //         Add Credit Note
      //       </div>
      //       <div className="popup-option-item" onClick={() => handleOptionClick('Manage Credit Note')}>
      //         Search and Manage Credit Note
      //       </div>
      //     </div>
      //   );
      // case 'Debit Note':
      //   return (
      //     <div className="popup-options">
      //       <div className="popup-option-item" onClick={() => handleOptionClick('Add Debit Note')}>
      //         Add Debit Note
      //       </div>
      //       <div className="popup-option-item" onClick={() => handleOptionClick('Manage Debit Note')}>
      //         Search and Manage Debit Note
      //       </div>
      //     </div>
      //   );
      default:
        return null;
    }
  };

  const renderTitle = () => {
    switch (selectedOption) {
      case 'Add Invoice':
        return 'Add Invoice';
      case 'Manage Invoice':
        return 'Search and Manage Invoice';
      case 'Add Sale Return':
        return 'Add Sale Return';
      case 'Manage Sale Return':
        return 'Search and Manage Sale Return';
      case 'Add Quotation':
        return 'Add Quotation';
      case 'Manage Quotation':
        return 'Search and Manage Quotation';
      case 'Add Delivery Note':
        return 'Add Delivery Note';
      case 'Manage Delivery Note':
        return 'Search and Manage Delivery Note';
      case 'Add Proforma Invoice':
        return 'Add Proforma Invoice';
      case 'Manage Proforma Invoice':
        return 'Search and Manage Proforma Invoice';
      case 'Add Sale Order':
        return 'Add Sale Order';
      case 'Manage Sale Order':
        return 'Search and Manage Sale Order';
      case 'Add Credit Note':
        return 'Add Credit Note';
      case 'Manage Credit Note':
        return 'Search and Manage Credit Note';
      case 'Add Debit Note':
        return 'Add Debit Note';
      case 'Manage Debit Note':
        return 'Search and Manage Debit Note';
      default:
        return '';
    }
  };

  return (
    <div className="container">
      {!selectedOption ? (
        <div className="favorites-content">
          <div className="options">
            {['Invoice', 'Sale Return', 'Quotation', 'Delivery Note', 'Proforma Invoice', 'Sale Order', ].map((option) => (
              <div
                key={option}
                className="option-item"
                onMouseEnter={() => handleMouseEnter(option)}
                onMouseLeave={handleMouseLeave}
              >
                <span>{option}</span> <ArrowForwardIosIcon className="arrow-icon" />
                {hoveredOption === option && renderPopupOptions(option)}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="output-container">
          <div className="title-bar">
            <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
            <h2>{renderTitle()}</h2>
          </div>
          <div className="output-content">
            {selectedOption === 'Add Invoice' && <SaleForm />}
            {selectedOption === 'Manage Invoice' && <SaleList />}
            {selectedOption === 'Add Sale Return' && <SaleForm />}
            {selectedOption === 'Manage Sale Return' && <SaleList />} {/* Replace with the appropriate component */}
            {selectedOption === 'Add Quotation' && <SaleForm />}
            {selectedOption === 'Manage Quotation' && <SaleList />} {/* Replace with the appropriate component */}
            {selectedOption === 'Add Delivery Note' && <SaleForm />}
            {selectedOption === 'Manage Delivery Note' && <SaleList />} {/* Replace with the appropriate component */}
            {selectedOption === 'Add Proforma Invoice' && <SaleForm />}
            {selectedOption === 'Manage Proforma Invoice' && <SaleList />} {/* Replace with the appropriate component */}
            {selectedOption === 'Add Sale Order' && <SaleForm />}
            {selectedOption === 'Manage Sale Order' && <SaleList />} {/* Replace with the appropriate component */}
        
          </div>
        </div>
      )}

<style jsx>{`
        .container {
          display: flex;
          width: 100%;
          padding: 10px;
          align-items: stretch;
        }

        .favorites-content {
          font-family: 'Poppins', sans-serif;
          width: 30%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .option-item {
          position: relative;
          padding: 12px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .option-item:hover {
          background-color: #f0f0f0;
        }

        .arrow-icon {
          font-size: 16px;
          color: #888;
        }

        .popup-options {
          position: absolute;
          top: 0;
          left: 100%;
          margin-left: 10px;
          background-color: #fff;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          padding: 10px;
          z-index: 10;
        }

        .popup-option-item {
          padding: 8px 12px;
          cursor: pointer;
          transition: background-color 0.2s;
          white-space: nowrap; /* Ensure items are single line */
        }

        .popup-option-item:hover {
          background-color: #e0e0e0;
        }

        .output-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .title-bar {
          display: flex;
          font-size: 12px;
          align-items: center;
          
        }

        .back-icon {
          cursor: pointer;
          font-size: 24px;
        }

        .output-content {
          flex-grow: 1;
          padding: 10px;
        }

        /* Media queries for responsive design */
        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .favorites-content,
          .output-content {
            width: 100%;
            height: auto;
          }
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          .favorites-content {
            width: 40%;
          }

          .output-content {
            width: 60%;
          }
        }
      `}</style>
    </div>
  );
};

export default Sale;
