import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Add, Edit, Delete, Save, Search, Cancel } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ServiceForm from './ServiceForm'; // Import the service form component

// Styled TextField with bottom border and search icon
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderBottom: '1px solid',
    },
    '&.Mui-focused fieldset': {
      borderBottom: '2px solid',
    },
  },
  '& .MuiInputAdornment-root': {
    marginLeft: -10,
  },
}));

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedService, setEditedService] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    // Fetch services from localStorage
    const storedServices = JSON.parse(localStorage.getItem('services')) || [];
    setServices(storedServices);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddService = () => {
    setIsFormOpen(true);
  };

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const updatedServices = services.filter((_, i) => i !== deleteIndex);
      setServices(updatedServices);
      localStorage.setItem('services', JSON.stringify(updatedServices));
    }
    handleDeleteClose();
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedService({ ...services[index] });
  };

  const handleSave = () => {
    const updatedServices = [...services];
    updatedServices[editIndex] = editedService;
    setServices(updatedServices);
    localStorage.setItem('services', JSON.stringify(updatedServices));
    setEditIndex(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredServices = services.filter(
    (service) =>
      service.serviceName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.product.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: '1rem',marginLeft:'-80px',width:'1000px',marginRight:'-50px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <StyledTextField
          label="Search Service"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
        />
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddService}>
          Add Service
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ height: '500px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
        <Table sx={{ minWidth: 1200 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>S.No</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Service Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Product</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Category</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Customer Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Customer Phone</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>CGST</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>SGST</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>IGST</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>CESS</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Service Rate</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Min. Service Rate</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Description</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredServices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  There is no data available
                </TableCell>
              </TableRow>
            ) : (
              filteredServices.map((service, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="serviceName"
                        value={editedService.serviceName}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.serviceName
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="product"
                        value={editedService.product}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.product
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="category"
                        value={editedService.category}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.category
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="customerName"
                        value={editedService.customerName}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.customerName
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="customerPhone"
                        value={editedService.customerPhone}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.customerPhone
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="cgst"
                        value={editedService.cgst}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.cgst
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="sgst"
                        value={editedService.sgst}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.sgst
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="igst"
                        value={editedService.igst}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.igst
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="cess"
                        value={editedService.cess}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.cess
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="serviceRate"
                        value={editedService.serviceRate}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.serviceRate
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="minServiceRate"
                        value={editedService.minServiceRate}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.minServiceRate
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="description"
                        value={editedService.description}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.description
                    )}
                  </TableCell>
                  <TableCell sx={{display: 'flex'}}>
                    {editIndex === index ? (
                      <>
                        <Button onClick={handleSave}  color="primary">
                          <Save />
                        </Button>
                        <IconButton onClick={() => setEditIndex(null)}>
                          <Cancel />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton onClick={() => handleEdit(index)} color="primary">
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteOpen(index)} color="error">
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this service?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Service Form Dialog */}
      <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)} fullWidth maxWidth='lg'>
        <DialogTitle>Add New Service</DialogTitle>
        <DialogContent>
          <ServiceForm onClose={() => setIsFormOpen(false)} />
        </DialogContent>
      </Dialog>

      {/* Custom Scrollbar Styling */}
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </Box>
  );
};

export default ServiceList;
