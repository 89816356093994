import React, { useState } from 'react';
import {
  Box, Button, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle,
  DialogContent,DialogContentText, DialogActions, useMediaQuery, useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddProduct from './AddProducts';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  padding: '5px 25px',
  textAlign: 'center',
}));

const ProductTable = () => {
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddProduct = (newProduct) => {
    setData([...data, newProduct]);
    setOpenAddProduct(false);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    // Logic to open edit dialog and prepopulate form with data
  };

  const handleDeleteConfirmation = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const handleDelete = () => {
    setData(data.filter((_, i) => i !== deleteIndex));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAddProduct(false);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.searchAddContainer}>
        <TextField
          placeholder="Search keyword . . ."
          variant="outlined"
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          sx={styles.addButton}
          onClick={() => setOpenAddProduct(true)}
        >
          Add New Product
        </Button>
      </Box>

      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>S. No.</StyledTableCell>
              <StyledTableCell>Group</StyledTableCell>
              <StyledTableCell>Brand</StyledTableCell>
              <StyledTableCell>Product Name</StyledTableCell>
              <StyledTableCell>Item Code</StyledTableCell>
              <StyledTableCell>Unit</StyledTableCell>
              <StyledTableCell>Purchase Price</StyledTableCell>
              <StyledTableCell>Sale Price</StyledTableCell>
              <StyledTableCell>Min. Sale Price</StyledTableCell>
              <StyledTableCell>M.R.P</StyledTableCell>
              <StyledTableCell>HSN</StyledTableCell>
              <StyledTableCell>CGST(%)</StyledTableCell>
              <StyledTableCell>SGST(%)</StyledTableCell>
              <StyledTableCell>IGST(%)</StyledTableCell>
              <StyledTableCell>Discount(%)</StyledTableCell>
              <StyledTableCell>Stock</StyledTableCell>
              <StyledTableCell>Low Level Limit</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{product.group}</TableCell>
                <TableCell>{product.brand}</TableCell>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.productCode}</TableCell>
                <TableCell>{product.unit}</TableCell>
                <TableCell>{product.purchasePrice}</TableCell>
                <TableCell>{product.salePrice}</TableCell>
                <TableCell>{product.minSalePrice}</TableCell>
                <TableCell>{product.mrp}</TableCell>
                <TableCell>{product.hsnCode}</TableCell>
                <TableCell>{product.cgst}</TableCell>
                <TableCell>{product.sgst}</TableCell>
                <TableCell>{product.igst}</TableCell>
                <TableCell>{product.saleDiscount}</TableCell>
                <TableCell>{product.openingStock}</TableCell>
                <TableCell>{product.lowLevelLimit}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(index)} aria-label="edit" sx={styles.editIconButton}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteConfirmation(index)} aria-label="delete" sx={styles.deleteIconButton}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Add Product Modal */}
      <Dialog
        open={openAddProduct}
        onClose={handleClose}
        fullScreen={fullScreen} // Enables full screen on small screens
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle sx={styles.fullScreenDialogTitle}>Add New Product</DialogTitle>
        <DialogContent sx={styles.fullScreenDialogContent}>
          <AddProduct onSave={handleAddProduct} />
        </DialogContent>
        <DialogActions sx={styles.fullScreenDialogActions}>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
const styles = {
  container: {
    padding: '20px 50px',
    minHeight: '60vh',
    width: '70vw',
    '@media (max-width: 600px)': {
      padding: '10px 20px',
    },
    '@media (min-width: 2560px)': {
      padding: '40px 100px',
    },
  },
  searchAddContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '3rem',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  addButton: {
    marginLeft: '2rem',
    '@media (max-width: 600px)': {
      marginLeft: 0,
      marginTop: '1rem',
    },
  },
  tableContainer: {
    minHeight: '400px',
    '@media (max-width: 600px)': {
      minHeight: '300px',
    },
    '@media (min-width: 2560px)': {
      minHeight: '600px',
    },
  },
  editIconButton: {
    color: 'blue',
  },
  deleteIconButton: {
    color: 'red',
  },
  fullScreenDialogTitle: {
    marginLeft: '130px',
  },
  smallDialogContent: {
    padding: '1rem 2rem',
    width: '100%', // Adjust width as needed
    boxSizing: 'border-box',
    backgroundColor:'none',
  },
  fullScreenDialogActions: {
    justifyContent: 'space-between',
    padding: '10px 24px',
  },
};


export default ProductTable;
