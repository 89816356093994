import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BusinessProfile from './BusinessProfile/BusinessProfile';
import Dashboard from './Inventory/Dasboard';
import Login from './BusinessProfile/Login';

// import ItemLedger from './Inventory/Reports/ReportsList/ItemLedger';
// import StockSummary from './Inventory/Reports/ReportsList/StockSummary';
// import ItemProgress from './Inventory/Reports/ReportsList/ItemProgress';
// import ItemWiseSale from './Inventory/Reports/ReportsList/ItemWiseSale';
// import PartyWiseSale from './Inventory/Reports/ReportsList/PartyWiseSale';
// import SaleTaxSummary from './Inventory/Reports/ReportsList/SaleTaxSummary';
// import DeliveryChallans from './Inventory/Reports/ReportsList/DeliveryChallans';
// import SaleOrder from './Inventory/Reports/ReportsList/SaleOrder';
// import SaleRegister from './Inventory/Reports/ReportsList/SaleRegister';
// import Ledger from './Inventory/Reports/ReportsList/Ledger';
// import CollectionSummary from './Inventory/Reports/ReportsList/CollectionSummary';
// import DayBook from './Inventory/Reports/ReportsList/DayBook';
// // import LedgerForm from './Expense/LedgerForm';
// import ItemForm from './Expense/ItemForm';
// import Order from './Inventory/Activity/Transaction/Sales/Order/Order';
// import OrderInvoice from './Inventory/Activity/Transaction/Sales/Order/OrderInvoice';
// import DeliveryChallan from './Inventory/Activity/Transaction/Sales/DeliveryChellan/DeliveryChallan';
// import DeliveryChallanInvoice from './Inventory/Activity/Transaction/Sales/DeliveryChellan/DeliveryInvoice';
// import Estimate from './Inventory/Activity/Transaction/Sales/Estimate/Estimate';
// import EstimateInvoice from './Inventory/Activity/Transaction/Sales/Estimate/EstimateInvoice';
// import Sale from './Inventory/Activity/Transaction/Sales/SaleInvoice/Sale';
// import SaleInvoice from './Inventory/Activity/Transaction/Sales/SaleInvoice/SaleInvoice';
// import SaleReturn from './Inventory/Activity/Transaction/Sales/SaleReturn/SaleReturn';
// import SaleReturnInvoice from './Inventory/Activity/Transaction/Sales/SaleReturn/SaleReturnInvoice';
// import Expense from './Inventory/Activity/Transaction/Purchase/Expense/Expense';
// import ExpenseInvoice from './Inventory/Activity/Transaction/Purchase/Expense/ExpenseInvoice';
// import PurchaseChallan from './Inventory/Activity/Transaction/Purchase/PurchaseChallan/PurchaseChallan';
// import PurchaseChallanInvoice from './Inventory/Activity/Transaction/Purchase/PurchaseChallan/PurchaseChallanInvoice';
// import Purchase from './Inventory/Activity/Transaction/Purchase/PurchaseInvoice/Purchase';
// import PurchaseInvoice from './Inventory/Activity/Transaction/Purchase/PurchaseInvoice/PurchaseInvoice';
// import PurchaseOrder from './Inventory/Activity/Transaction/Purchase/PurchaseOrder/PurchaseOrder';
// import PurchaseOrderInvoice from './Inventory/Activity/Transaction/Purchase/PurchaseOrder/PurchaseOrderInvoice';
// import PurchaseReturn from './Inventory/Activity/Transaction/Purchase/PurchaseReturn/PurchaseReturn';
// import PurchaseReturnInvoice from './Inventory/Activity/Transaction/Purchase/PurchaseReturn/PurchaseReturnInvoice'
// import Transport from './Inventory/Reports/ReportsList/Transport';
// import PurchaseOrders from './Inventory/Reports/ReportsList/PurchaseOrder';
// import PurchaseChallans from './Inventory/Reports/ReportsList/PurchaseChallan';
// import PurchaseRegister from './Inventory/Reports/ReportsList/PurchaseRegister';
// import ItemWisePurchase from './Inventory/Reports/ReportsList/ItemWisePurchase';
// import PartyWisePurchase from './Inventory/Reports/ReportsList/PartyWisePurchase';
// import PurchaseTaxSummary from './Inventory/Reports/ReportsList/PurchaseTaxSummary';
// import Paymentin from './Expense/Paymentin';
// import Paymentout from './Expense/Paymentout';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define your routes here */}
          <Route path="/" element={<BusinessProfile />} />
          <Route path='/dashboard' element={<Dashboard />}/>
          <Route path='login' element={<Login />}></Route>

          {/* <Route path='/activity/order' element={<Order />} />
          <Route path='/activity/orderinvoice' element={<OrderInvoice />} />
          <Route path='/activity/deliverychallan' element={<DeliveryChallan />} />
          <Route path='/activity/deliverychallaninvoice' element={<DeliveryChallanInvoice />}/>
          <Route path='/activity/estimate' element={<Estimate />}/>
          <Route path='/activity/estimateinvoice' element={<EstimateInvoice />}/>
          <Route path='/activity/sale' element={<Sale />}/>
          <Route path='/activity/saleinvoice' element={<SaleInvoice />} />
          <Route path='/activity/salereturn' element={<SaleReturn />} />
          <Route path='/activity/salereturninvoice' element={<SaleReturnInvoice />} />

          <Route path='/activity/expense' element={<Expense />} />
          <Route path='/activity/expenseinvoice' element={<ExpenseInvoice />} />
          <Route path='/activity/purchasechallan' element={<PurchaseChallan />} />
          <Route path='/activity/purchasechallaninvoice' element={<PurchaseChallanInvoice />} />
          <Route path='/activity/purchase' element={<Purchase />} />
          <Route path='/activity/purchaseinvoice' element={<PurchaseInvoice />} />
          <Route path='/activity/purchaseorder' element={<PurchaseOrder />} />
          <Route path='/activity/purchaseorderinvoice' element={<PurchaseOrderInvoice />} />
          <Route path='/activity/purchasereturn' element={<PurchaseReturn />} />
          <Route path='/activity/purchasereturninvoice' element={<PurchaseReturnInvoice />} />
          <Route path='/activity/paymentin' element={<Paymentin />} />
          <Route path='/activity/paymentout' element={<Paymentout />} /> */}



          {/* <Route path='/expense' element={<LedgerForm />} /> */}
          {/* <Route path='/itemform' element={<ItemForm />} /> */}

          {/* Report List Route Paths  */}
          {/* <Route path='reports/item-ledger' element={<ItemLedger/>} />
          <Route path='reports/stock-summary' element={<StockSummary />} />
          <Route path='reports/item-progress' element={<ItemProgress />} />
          <Route path='reports/itemwise-sale' element={<ItemWiseSale />} />
          <Route path='reports/partywise-sale'element={<PartyWiseSale />} />
          <Route path='reports/sale-tax-summary' element={<SaleTaxSummary />} />
          <Route path='reports/delivery-challan' element={<DeliveryChallans />} />
          <Route path='reports/sale-order'  element={<SaleOrder />} />
          <Route path='reports/sale-register' element={<SaleRegister />} />
          <Route path='reports/ledger' element={<Ledger />} />
          <Route path='reports/day-book' element={<DayBook />} />
          <Route path='reports/collection-summary' element={<CollectionSummary />}/>
          <Route path='reports/purchase-order' element={<PurchaseOrders />}/>
          <Route path='reports/purchase-challan' element={<PurchaseChallans />}/>
          <Route path='reports/transport' element={<Transport />}/>
          <Route path='reports/purchase-register' element={<PurchaseRegister />}/>
          <Route path='reports/itemwise-purchase' element={<ItemWisePurchase />} />
          <Route path='reports/partywise-purchase' element={<PartyWisePurchase />} />
          <Route path='reports/purchase-tax-summary' element={<PurchaseTaxSummary />} /> */}





          


          
          
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
