import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Add, Edit, Delete, Save, Search } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CustomerForm from './CustomerForm';
// Import LedgerForm

// Styled TextField with bottom border and search icon
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderBottom: '1px solid',
    },
    '&.Mui-focused fieldset': {
      borderBottom: '2px solid',
    },
  },
  '& .MuiInputAdornment-root': {
    marginLeft: -10,
  },
}));

const CustomerList = () => {
  const location = useLocation();
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedCustomer, setEditedCustomer] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false); // State for modal visibility

  useEffect(() => {
    // Fetch customers from localStorage
    const storedCustomers = JSON.parse(localStorage.getItem('customers')) || [];
    setCustomers(storedCustomers);
  }, [location]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddClient = () => {
    setIsFormOpen(true); // Open the modal
  };

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const updatedCustomers = customers.filter((_, i) => i !== deleteIndex);
      setCustomers(updatedCustomers);
      localStorage.setItem('customers', JSON.stringify(updatedCustomers));
    }
    handleDeleteClose();
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedCustomer({ ...customers[index] });
  };

  const handleSave = () => {
    const updatedCustomers = [...customers];
    updatedCustomers[editIndex] = editedCustomer;
    setCustomers(updatedCustomers);
    localStorage.setItem('customers', JSON.stringify(updatedCustomers));
    setEditIndex(null); // Exit edit mode
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredCustomers = customers.filter(
    (customer) =>
      customer.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.contactNo.includes(searchTerm)
  );

  return (
    <Box sx={{ padding: '-2rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <StyledTextField
          label="Search Customer"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
        />
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddClient}>
          Add Client
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ height: '560px', overflowY: 'auto', scrollbarWidth: 'thin', boxShadow:'10' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Client ID</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Customer Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Address</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Contact No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>State</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>GSTIN</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>A/c Balance</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  There is no data available
                </TableCell>
              </TableRow>
            ) : (
              filteredCustomers.map((customer, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="fullName"
                        value={editedCustomer.fullName}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.fullName
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="billingAddress"
                        value={editedCustomer.billingAddress}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.billingAddress
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="contactNo"
                        value={editedCustomer.contactNo}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.contactNo
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="state"
                        value={editedCustomer.state}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.state
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="gstNo"
                        value={editedCustomer.gstNo}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.gstNo
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="openingBalance"
                        value={editedCustomer.openingBalance}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.openingBalance
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <IconButton color="primary" onClick={handleSave}>
                        <Save />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton  style={{color:'blue'}}  onClick={() => handleEdit(index)}>
                          <Edit />
                        </IconButton>
                        <IconButton  style={{color:'red'}}  onClick={() => handleDeleteOpen(index)}>
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={open}
        onClose={handleDeleteClose}
        aria-labelledby="delete-confirmation-dialog"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this customer?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* LedgerForm Modal */}
      <Dialog
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={{fontWeight: '700'}}>Add New Client</DialogTitle>
        <DialogContent>
          <CustomerForm />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>

      {/* Custom Scrollbar Styling */}
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </Box>
  );
};

export default CustomerList;