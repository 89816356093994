import React, { useState } from 'react';
import {
  Container, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Box, IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // Add this for table support in PDF
import { SaveAlt as ExportExcelIcon, PictureAsPdf as ExportPDFIcon, Print as PrintIcon, FilterList as FilterIcon } from '@mui/icons-material';

const claymorphismStyle = {
  boxShadow: '9px 9px 16px rgba(0, 0, 0, 0.2), -9px -9px 16px rgba(255, 255, 255, 0.8)',
  background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
  borderRadius: '20px',
  padding: '20px',
};

const sampleData = [
  { date: '01-Aug-2024', party: 'Party A', number: 'INV001', invoiceType: 'GST', gstin: '123456', grossAmount: 1000, discount: 50, taxableValue: 950, cgst: 47.5, sgst: 47.5, igst: 0, cessAmount: 0, otherCharges: 0, ro: 0, netAmount: 1045 },
  // Add more sample data here
];

const SaleTaxSummary = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const formatDate = (date) => dayjs(date).format('MM/DD/YYYY');

  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SaleTaxSummary');
    XLSX.writeFile(workbook, 'SaleTaxSummary.xlsx');
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Sale Tax Summary Report', 14, 10);
    doc.text(`Period: ${formatDate(selectedDate)} to ${formatDate(selectedDate)}`, 14, 20);
    
    // Use autoTable for better table formatting in PDF
    autoTable(doc, {
      startY: 30,
      head: [['Date', 'Party', 'Number', 'Invoice Type', 'GSTIN', 'Gross Amount', 'Discount', 'Taxable Value', 'CGST', 'SGST', 'IGST', 'Cess Amount', 'Other Charges', 'R/O', 'Net Amount']],
      body: sampleData.map(row => [
        row.date, row.party, row.number, row.invoiceType, row.gstin, row.grossAmount, row.discount, row.taxableValue, row.cgst, row.sgst, row.igst, row.cessAmount, row.otherCharges, row.ro, row.netAmount
      ]),
    });
    doc.save('SaleTaxSummary.pdf');
  };

  return (
    <Container>
      <Paper style={claymorphismStyle} sx={{ p: 3, mt: 4, overflow: 'hidden' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
          <Typography variant="h5" gutterBottom>
            Sale Tax Summary
          </Typography>
          <Box display="flex" justifyContent="flex-end" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
            <Button variant="contained" onClick={exportExcel} startIcon={<ExportExcelIcon />} sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}>
              Export Excel
            </Button>
            <Button variant="contained" onClick={exportPDF} startIcon={<ExportPDFIcon />} sx={{ mr: { sm: 1 } }}>
              Export PDF
            </Button>
            <IconButton color="primary">
              <PrintIcon />
            </IconButton>
            <IconButton color="primary">
              <FilterIcon />
            </IconButton>
          </Box>
        </Box>

        <Typography variant="body1" gutterBottom>
          Period: {formatDate(selectedDate)} to {formatDate(selectedDate)}
        </Typography>

        <Box display="flex" justifyContent="flex-end" mb={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['month', 'year', 'day']}
              label="Select Date"
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              format="MM/DD/YYYY"
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>
        </Box>

        {/* Scrollable Table */}
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ mt: 2, minWidth: 650 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Party</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Invoice Type</TableCell>
                <TableCell>GSTIN</TableCell>
                <TableCell>Gross Amount</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Taxable Value</TableCell>
                <TableCell>CGST</TableCell>
                <TableCell>SGST/UTGST</TableCell>
                <TableCell>IGST</TableCell>
                <TableCell>Cess Amount</TableCell>
                <TableCell>Other Charges</TableCell>
                <TableCell>R/O</TableCell>
                <TableCell>Net Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sampleData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.party}</TableCell>
                  <TableCell>{row.number}</TableCell>
                  <TableCell>{row.invoiceType}</TableCell>
                  <TableCell>{row.gstin}</TableCell>
                  <TableCell>{row.grossAmount}</TableCell>
                  <TableCell>{row.discount}</TableCell>
                  <TableCell>{row.taxableValue}</TableCell>
                  <TableCell>{row.cgst}</TableCell>
                  <TableCell>{row.sgst}</TableCell>
                  <TableCell>{row.igst}</TableCell>
                  <TableCell>{row.cessAmount}</TableCell>
                  <TableCell>{row.otherCharges}</TableCell>
                  <TableCell>{row.ro}</TableCell>
                  <TableCell>{row.netAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </Container>
  );
};

export default SaleTaxSummary;
