import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
  Paper,
  Container,
  Modal,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';

const InventoryForm = () => {
  const [date, setDate] = useState(dayjs());
  const [adjustmentType, setAdjustmentType] = useState('credit');
  const [adjustmentReason, setAdjustmentReason] = useState('');
  const [itemCode, setItemCode] = useState('');
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [amount, setAmount] = useState('');
  const [remarks, setRemarks] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const handleSave = () => {
    if (!adjustmentReason || !itemName) {
      setError('Adjustment Reason and Item Name are required.');
      return;
    }

    const formData = {
      date: date.format('YYYY-MM-DD'),
      adjustmentType,
      adjustmentReason,
      itemCode,
      itemName,
      quantity,
      amount,
      remarks,
    };

    let existingData = JSON.parse(localStorage.getItem('adjustmentFormData')) || [];

    if (!Array.isArray(existingData)) {
      console.error('Stored data is not an array. Initializing as an empty array.');
      existingData = [];
    }

    localStorage.setItem('adjustmentFormData', JSON.stringify([...existingData, formData]));

    setError('');
    setOpen(true);
    
    setDate(dayjs());
    setAdjustmentType('credit');
    setAdjustmentReason('');
    setItemCode('');
    setItemName('');
    setQuantity('');
    setAmount('');
    setRemarks('');
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container
        maxWidth="l"
        style={{
          overflow: 'auto',
          minHeight: '60vh',
          boxSizing: 'border-box',
          marginLeft:'8rem',
          marginTop:'-4rem'
        }}
      >
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="80vh" // Adjusted height
          style={{
            overflow: 'auto',
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: '18px',
              width: '100%',
              borderRadius: '8px',
              boxSizing: 'border-box',
              height: '60%', // Ensure Paper takes full height of Box
              maxHeight: '80vh', // Limit height to 80vh
              overflow: 'auto', // Enable scrolling within Paper if needed
            }}
          >
            <Typography variant="h6" style={{ marginBottom: '16px' }}>
              Item Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Adjustment Date"
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">Adjustment Type</FormLabel>
                  <RadioGroup
                    row
                    value={adjustmentType}
                    onChange={(e) => setAdjustmentType(e.target.value)}
                  >
                    <FormControlLabel value="credit" control={<Radio />} label="Credit (+)" />
                    <FormControlLabel value="debit" control={<Radio />} label="Debit (-)" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal" required error={!!error}>
                  <InputLabel>Adjustment Reason</InputLabel>
                  <Select
                    value={adjustmentReason}
                    onChange={(e) => setAdjustmentReason(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Stock Takeoff">Stock Takeoff</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {error && <Typography color="error">{error}</Typography>}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Item Code"
                  value={itemCode}
                  onChange={(e) => setItemCode(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Item Name"
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Quantity"
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: <Typography style={{ paddingRight: '4px' }}>₹</Typography>,
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Remarks"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" marginTop="5px">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    startIcon={<SaveIcon />}
                    style={{
                      width: '100px',
                      backgroundColor: '#1976d2',
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        {/* Success Modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            bgcolor="background.paper"
            border="2px solid #000"
            boxShadow={24}
            p={4}
            style={{ outline: 'none', textAlign: 'center', borderRadius: '8px' }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Success!
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              Your data has been saved successfully.
            </Typography>
            <Button onClick={handleClose} variant="contained" style={{ marginTop: '16px' }}>
              Close
            </Button>
          </Box>
        </Modal>
      </Container>
    </LocalizationProvider>
  );
};

export default InventoryForm;
