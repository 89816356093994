import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddGroup from './AddGroup';
import AddBrand from './AddBrand';
import AddUnit from './AddUnit';
import AddProduct from './AddProducts';
import ProductTable from './ProductTable';

const Master = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [discountSchemaOpen, setDiscountSchemaOpen] = useState(false);
  const [miscellaneousOpen, setMiscellaneousOpen] = useState(false);

  const handleOptionClick = (option) => {
    if (option === 'discount-schemas') {
      setDiscountSchemaOpen(!discountSchemaOpen);
    } else if (option === 'miscellaneous') {
      setMiscellaneousOpen(!miscellaneousOpen);
    } else {
      setSelectedOption(option);
    }
  };

  const handleBackClick = () => {
    setSelectedOption(null);
    setDiscountSchemaOpen(false);
    setMiscellaneousOpen(false);
  };

  return (
    <div className="container">
      {!selectedOption ? (
        <div className="options-content">
          <div className="options">
            <div className="option-item" onClick={() => handleOptionClick('add-product')}>
              <span>Add Product</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            <div className="option-item" onClick={() => handleOptionClick('search-manage-product')}>
              <span>Search and Manage Products</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            {/* <div
              className="option-item"
              onMouseEnter={() => setDiscountSchemaOpen(true)}
              onMouseLeave={() => setDiscountSchemaOpen(false)}
            >
              <span>Discount Schemas</span> <ArrowForwardIosIcon className="arrow-icon" />
              {discountSchemaOpen && (
                <div className="dropdown-menu">
                  <div className="dropdown-item" onClick={() => handleOptionClick('add-discount-schema')}>
                    <span>Add Discount Schema</span>
                    <ArrowForwardIosIcon className="arrow-icon" />
                  </div>
                  <div className="dropdown-item" onClick={() => handleOptionClick('search-manage-discount-schema')}>
                    <span>Search and Manage Discount Schema</span>
                    <ArrowForwardIosIcon className="arrow-icon" />
                  </div>
                </div>
              )}
            </div> */}

            {/* Additional Options */}
            <div className="option-item" onClick={() => handleOptionClick('brand-master')}>
              <span>Brand Master</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            <div className="option-item" onClick={() => handleOptionClick('group-master')}>
              <span>Group Master</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            <div className="option-item" onClick={() => handleOptionClick('bank-master')}>
              <span>Bank Master</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            <div
              className="option-item"
              onMouseEnter={() => setMiscellaneousOpen(true)}
              onMouseLeave={() => setMiscellaneousOpen(false)}
            >
              <span>Miscellaneous</span> <ArrowForwardIosIcon className="arrow-icon" />
              {miscellaneousOpen && (
                <div className="dropdown-menu">
                  <div className="dropdown-item" onClick={() => handleOptionClick('unit-master')}>
                    <span>Unit Master</span>
                    <ArrowForwardIosIcon className="arrow-icon" />
                  </div>
                  <div className="dropdown-item" onClick={() => handleOptionClick('expense-master')}>
                    <span>Expense Master</span>
                    <ArrowForwardIosIcon className="arrow-icon" />
                  </div>
                  <div className="dropdown-item" onClick={() => handleOptionClick('holiday-master')}>
                    <span>Holiday Master</span>
                    <ArrowForwardIosIcon className="arrow-icon" />
                  </div>
                  <div className="dropdown-item" onClick={() => handleOptionClick('department-master')}>
                    <span>Department Master</span>
                    <ArrowForwardIosIcon className="arrow-icon" />
                  </div>
                  <div className="dropdown-item" onClick={() => handleOptionClick('location-master')}>
                    <span>Location Master</span>
                    <ArrowForwardIosIcon className="arrow-icon" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="output-container">
          <div className="header">
            <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
            <h2 className="title">
              {selectedOption === 'add-product' && 'Add Product'}
              {selectedOption === 'search-manage-product' && 'Search and Manage Products'}
              {selectedOption === 'add-discount-schema' && 'Add Discount Schema'}
              {selectedOption === 'search-manage-discount-schema' && 'Search and Manage Discount Schema'}
              {selectedOption === 'brand-master' && 'Brand Master'}
              {selectedOption === 'group-master' && 'Group Master'}
              {selectedOption === 'bank-master' && 'Bank Master'}
              {selectedOption === 'unit-master' && 'Unit Master'}
              {selectedOption === 'expense-master' && 'Expense Master'}
              {selectedOption === 'holiday-master' && 'Holiday Master'}
              {selectedOption === 'department-master' && 'Department Master'}
              {selectedOption === 'location-master' && 'Location Master'}
            </h2>
          </div>
          <div className="output-content">
            {selectedOption === 'add-product' && <AddProduct />}
            {selectedOption === 'search-manage-product' && <ProductTable />}
            {selectedOption === 'add-discount-schema' && ''}
            {selectedOption === 'search-manage-discount-schema' && ""}
            {selectedOption === 'brand-master' && <AddBrand />}
            {selectedOption === 'group-master' && <AddGroup />}
            {selectedOption === 'bank-master' && ""}
            {selectedOption === 'unit-master' && <AddUnit />}
            {selectedOption === 'expense-master' && ""}
            {selectedOption === 'holiday-master' && ""}
            {selectedOption === 'department-master' && ""}
            {selectedOption === 'location-master' && ""}
          </div>
        </div>
      )}

      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          padding: 10px;
          align-items: stretch;
        }

        .options-content {
          font-family: 'Poppins', sans-serif;
          width: 30%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .option-item {
          padding: 12px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
          position: relative;
        }

        .option-item:hover {
          background-color: #f0f0f0;
        }

        .arrow-icon {
          font-size: 16px;
          color: #888;
        }

        .dropdown-menu {
          position: absolute;
          top: 0;
          left: 100%;
          margin-left: 10px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 10px;
          z-index: 10;
          width: 220px; /* Adjust width as needed */
        }

        .dropdown-item {
          padding: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .dropdown-item:hover {
          background-color: #f0f0f0;
        }

        .output-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .header {
          display: flex;
          align-items: center;
         
        }

        .back-icon {
          margin-right: 10px;
          cursor: pointer;
          font-size: 24px;
        }

        .title {
          font-size: 18px;
          font-weight: bold;
          flex-grow: 1;
        }

        .output-content {
        
          flex-grow: 1;
          
        }

        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .options-content {
            width: 100%;
            margin-right: 0;
          }

          .output-content {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default Master;
