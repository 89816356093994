
import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Search, Add } from '@mui/icons-material';
import InventoryForm from './InventoryForm';

const InventoryTable = () => {
  const [adjustments, setAdjustments] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('adjustmentFormData')) || [];
    if (Array.isArray(storedData)) {
      setAdjustments(storedData);
    } else {
      setAdjustments([]);
    }
  }, []);

  const handleSaveAdjustment = (newAdjustment) => {
    const updatedAdjustments = [...adjustments, newAdjustment];
    setAdjustments(updatedAdjustments);
    localStorage.setItem('adjustmentFormData', JSON.stringify(updatedAdjustments));
    setIsFormOpen(false);
  };

  const filteredAdjustments = adjustments.filter((adjustment) => {
    return (
      adjustment.itemName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      adjustment.adjustmentReason.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <Paper style={{ padding: '16px', width: '100%', marginTop:'5rem',marginLeft:'10rem' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Search</Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Product Name or Adjustment Reason"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton edge="end">
                  <Search />
                </IconButton>
              ),
            }}
            style={{
              width: isMobile ? '100%' : '50%',
              marginLeft: isMobile ? '0' : '16px',
              marginTop: isMobile ? '8px' : '0',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} container spacing={2} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setIsFormOpen(true)}
            style={{ marginTop: isMobile ? '8px' : '0' }}
          >
            Add New Stock Adjustment
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h6" style={{ marginTop: '16px' }}>
        Recent Stock Adjustment(s)
      </Typography>

      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table size={isMobile ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              <TableCell>S. No.</TableCell>
              <TableCell>Adjust Type</TableCell>
              <TableCell>Item Name</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAdjustments.map((adjustment, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{adjustment.adjustmentType?.toUpperCase()}</TableCell>
                <TableCell>{adjustment.itemName}</TableCell>
                <TableCell>{adjustment.adjustmentReason}</TableCell>
                <TableCell>{adjustment.quantity}</TableCell>
                <TableCell>₹{adjustment.amount}</TableCell>
                <TableCell>{adjustment.remarks}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Adjustment Form Modal */}
      <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Add New Stock Adjustment</DialogTitle>
        <DialogContent>
          <InventoryForm onSave={handleSaveAdjustment} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFormOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default InventoryTable;
