import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, TextField, Radio, RadioGroup, FormControlLabel, Card, Link } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend, Label } from 'recharts';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import CustomerForm from '../../Customer/CustomerForm';
import ExpenseForm from '../../Expense/Expenceform';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gap: '20px',
    padding: '10px 20px 0 20px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '70%',
    height: '90%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
    zIndex: 10,
    padding: '1rem',
    overflowY: 'auto',
    marginLeft: '350px',
    marginTop: '120px',
    borderRadius: '8px',
  },
  arrowBackIcon: {
    cursor: 'pointer',
    marginBottom: '1rem',
  },
  sidebar: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
  },
  quickInfo: {
    marginBottom: '1rem',
    color: '#fff',
    padding: '30px 30px 10px 30px',
    backgroundColor: '#307a74',
    borderRadius: '10px',
    boxShadow: ' 12px 12px 16px -5px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(20, 113, 94, 0.3), inset 12px 12px 18px rgba(35, 148, 132, 0.6)',
    position: 'relative',
    marginBottom: '5px',
    textAlign: 'center',
  },
  quickInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
    textAlign: 'justify',
  },
  todayButton: {
    marginBottom: '0.5rem',
    backgroundColor: '#ffc107',
    color: '#000',
    position: 'absolute',
    right: 10,
    top: 10,
    padding: '5px 10px',
    borderRadius: '3px',
    fontWeight: 'bold',
  },
  button: {
    marginBottom: '0.5rem',
    padding: '10px',
    backgroundColor: '#307a74',
    borderRadius: '5px',
    boxShadow: ' 12px 12px 16px -5px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(20, 113, 94, 0.3), inset 12px 12px 18px rgba(35, 148, 132, 0.6)',
    color: '#fff',
    fontSize: '12px',
    textAlign: 'center',
    cursor: 'pointer',
    width: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gap: '20px',
  },
  graphArea: {
    gridColumn: '1 / 4',
    height: '300px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '6px 6px 6px -5px rgba(0, 0, 0, 0.1),-6px -6px 6px -5px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    position: 'relative',
  },
  graphContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90%',
  },
  graphButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
    gap: '5px',
    fontSize: '5px',
  },
  graphButton: {
    padding: '5px 10px',
    fontSize: '12px',
    backgroundColor: '#307a74',
    borderRadius: '5px',
    boxShadow: ' 12px 12px 16px -5px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(20, 113, 94, 0.3), inset 12px 12px 18px rgba(35, 148, 132, 0.6)',
    color: '#fff',
    cursor: 'pointer',
    minWidth: '80px',
  },
  card: {
    height: '300px',
    padding: '3px 3px 3px 10px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '6px 6px 6px -5px rgba(0, 0, 0, 0.1),-6px -6px 6px -5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    borderRadius: '25px',
  },
  pieChart: {
    width: '100%',
    height: '100%',
  },

  // Media Queries for responsiveness
  '@media (max-width: 1200px)': {
    container: {
      gridTemplateColumns: '1fr',
      padding: '15px',
    },
    overlay: {
      width: '85%',
      marginLeft: '250px',
      marginTop: '80px',
    },
    main: {
      gridTemplateColumns: '1fr',
      gap: '15px',
    },
    graphArea: {
      height: '250px',
    },
  },
  '@media (max-width: 768px)': {
    container: {
      padding: '10px',
    },
    overlay: {
      width: '90%',
      marginLeft: '20px',
      marginTop: '60px',
    },
    sidebar: {
      gridTemplateColumns: '1fr',
    },
    main: {
      gridTemplateColumns: '1fr',
      gap: '10px',
    },
    quickInfo: {
      padding: '20px',
    },
    graphArea: {
      height: '200px',
    },
  },
  '@media (max-width: 480px)': {
    container: {
      padding: '5px',
    },
    overlay: {
      width: '100%',
      marginLeft: '10px',
      marginTop: '40px',
    },
    sidebar: {
      gridTemplateColumns: '1fr',
    },
    main: {
      gridTemplateColumns: '1fr',
      gap: '5px',
    },
    quickInfo: {
      padding: '10px',
    },
    graphArea: {
      height: '150px',
    },
    button: {
      padding: '8px',
      fontSize: '12px',
    },
  },
  '@media (min-width: 1920px)': {
    container: {
      padding: '30px',
    },
    overlay: {
      width: '60%',
      marginLeft: '500px',
      marginTop: '150px',
    },
    main: {
      gridTemplateColumns: '2fr 1fr 1fr',
      gap: '30px',
    },
    graphArea: {
      height: '400px',
    },
    quickInfo: {
      padding: '40px',
    },
    button: {
      padding: '12px',
      fontSize: '16px',
    },
  },
};


const graphs = {
  recentSales: {
    title: 'Recent Sales',
    data: [
      { name: 'Cloth', value: 400 },
      { name: 'Jewel', value: 600 },
      { name: 'Food', value: 300 },
      { name: 'Grocery', value: 150 },
      { name: 'mobiles', value: 100 },
    ],
    colors: ['#0088FE', '#FF0088', '#BBFF28', '#FF8042', '#FFBB28'],
  },
  customerDue: {
    title: 'Customer Due',
    data: [
      { name: 'Product A', value: 500 },
      { name: 'Product B', value: 250 },
      { name: 'Product C', value: 150 },
    ],
    colors: ['#00C49F', '#FFBB28', '#FF8042'],
  },
  supplierDue: {
    title: 'Supplier Due',
    data: [
      { name: 'Service A', value: 100 },
      { name: 'Service B', value: 300 },
      { name: 'Service C', value: 600 },
    ],
    colors: ['#FFBB28', '#00C49F', '#FF0088'],
  },
  amountReceived: {
    title: 'Amount Received',
    data: [
      { name: 'Category A', value: 400 },
      { name: 'Category B', value: 500 },
    ],
    colors: ['#FF8042', '#0088FE'],
  },
  amountPaid: {
    title: 'Amount Paid',
    data: [
      { name: 'Expenses A', value: 700 },
      { name: 'Expenses B', value: 300 },
    ],
    colors: ['#FFBB28', '#00C49F'],
  },
  customerCashChequeAlert: {
    title: 'Customer Cash / Cheque Alert',
    data: [
      { name: 'Alert A', value: 200 },
      { name: 'Alert B', value: 800 },
    ],
    colors: ['#FF8042', '#0088FE'],
  },
};

const distributionData = [
  { name: 'Category A', value: 400 },
  { name: 'Category B', value: 300 },
  { name: 'Category C', value: 300 },
  { name: 'Category D', value: 200 },
];

const BusinessDashboard = () => {

  const [searchPlaceholder, setSearchPlaceholder] = useState('Search...');
  const [selectedGraph, setSelectedGraph] = useState('recentSales');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeComponent, setActiveComponent] = useState(null);
  const [distributionData, setDistributionData] = useState([
    // { name: 'Category A', value: 400 },
    // { name: 'Category B', value: 300 },
    // { name: 'Category C', value: 300 },
    { name: 'Category D', value: 200 },
  ]);


  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const handleBackClick = () => {
    setActiveComponent(null);
  };

  useEffect(() => {
    // Fetch data from the backend once connected
    // Replace this with actual API call to fetch distribution data
    // Example: fetchDistributionData();
  }, []);

  const COLORS = ['#00C49F', '#FF8042', '#FFBB28', '#00C49F'];


  const handleRadioChange = (event) => {
    switch (event.target.value) {
      case 'stock':
        setSearchPlaceholder('Item name or Item code...');
        break;
      case 'serialNo':
        setSearchPlaceholder('Search Serial No...');
        break;
      case 'invoice':
        setSearchPlaceholder('Invoice No or Customer Name or Mobile...');
        break;
      case 'customer':
        setSearchPlaceholder('Customer Name or Contact No...');
        break;
      case 'supplier':
        setSearchPlaceholder('Supplier Name or Contact No...');
        break;
      default:
        setSearchPlaceholder('Search...');
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Implement search functionality here
      console.log(`Searching for: ${searchTerm}`);
    }
  };
  

  const handleGraphChange = (graphKey) => {
    setSelectedGraph(graphKey);
  };

 

  return (
    <Box>
      {/* <Typography style={styles.alert}>
        Alert!! Please set up your backup locations to activate automated backup.
      </Typography> */}
      <Box style={styles.container}>
        {/* Sidebar */}
        <Box>
      {activeComponent ? (
        <Box style={styles.overlay}> 
          <ArrowBackIcon style={styles.arrowBackIcon} onClick={handleBackClick} />
          {activeComponent === 'NewInvoice' && <div>New Invoice Component</div>}
          {activeComponent === 'NewQuotation' && <div>New Quotation Component</div>}
          {activeComponent === 'AddPurchase' && <div>Add Purchase Component</div>}
          {activeComponent === 'AddExpense' && <ExpenseForm />}
          {activeComponent === 'AddCustomer' && <CustomerForm />}
          {activeComponent === 'AddReminder' && <div>Add Reminder Component</div>}
          {activeComponent === 'PaymentIn' && <div>Payment In Component</div>}
          {activeComponent === 'PaymentOut' && <div>Payment Out Component</div>}
        </Box>
      ) : (
        <Box style={styles.sidebar}>
          <Box style={{ gridColumn: 'span 2' }}>
            <Box style={styles.quickInfo}>
              <Typography variant="h6" style={{textAlign:'left',marginTop:'-1rem'}}>QUICK INFO</Typography>
              <Button style={styles.todayButton}>TODAY</Button>
              <Box style={styles.quickInfoItem}>
                <Typography>GROSS SALE: ₹ 0</Typography>
                <Typography>AMOUNT RECEIVED: ₹ 0</Typography>
                <Typography>AMOUNT DUE: ₹ 0</Typography>
              </Box>
            </Box>
          </Box>
          <Button style={styles.button} onClick={() => handleButtonClick('NewInvoice')}>New Invoice</Button>
          <Button style={styles.button} onClick={() => handleButtonClick('NewQuotation')}>New Quotation</Button>
          <Button style={styles.button} onClick={() => handleButtonClick('AddPurchase')}>Add Purchase</Button>
          <Button style={styles.button} onClick={() => handleButtonClick('AddExpense')}>Add Expense</Button>
          <Button style={styles.button} onClick={() => handleButtonClick('AddCustomer')}>Add Customer</Button>
          <Button style={styles.button} onClick={() => handleButtonClick('AddReminder')}>Add Reminder</Button>
          <Button style={styles.button} onClick={() => handleButtonClick('PaymentIn')}>Payment In</Button>
          <Button style={styles.button} onClick={() => handleButtonClick('PaymentOut')}>Payment Out</Button>
        </Box>
      )}
    </Box>
        {/* Main Content */}
        <Box style={styles.main}>
          {/* Graph Area */}
          <Box style={styles.graphArea}>
            <Box style={styles.graphButtons}>
              {Object.keys(graphs).map((key) => (
                <Button
                  key={key}
                  style={styles.graphButton}
                  onClick={() => handleGraphChange(key)}
                >
                  {graphs[key].title}
                </Button>
              ))}
            </Box>
            <Box style={styles.graphContent}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={200} height={200}>
                  <Pie
                    data={graphs[selectedGraph].data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label={({ name, value }) => `${name}: ${value}`}
                  >
                    {graphs[selectedGraph].data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={graphs[selectedGraph].colors[index % graphs[selectedGraph].colors.length]} />
                    ))}
                    <Label position="center" />
                  </Pie>
                  <Tooltip />
                  <Legend verticalAlign="top" height={36}/>
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Box>

          {/* Cards and other content */}
          <Card style={styles.card}>
            <Typography variant="h6" sx={{  textAlign: 'center', color: '#031496',paddingTop:'8px',fontWeight: 'bold',fontSize:'15px' }}>
              DISTRIBUTION IN LAST 30 DAYS
            </Typography>
            <ResponsiveContainer width="100%" height={270}>
              <PieChart>
                <Pie
                  data={distributionData}
                  cx="60%"
                  cy="40%"
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label={({ name, value }) => `${name}: ${value}`}
                >
                  {distributionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend verticalAlign="top" height={36  } />
              </PieChart>
            </ResponsiveContainer>
          </Card>


          
          <Card style={styles.card}>
            <Typography variant="h6" sx={{ width:'100%',textAlign:'center', color: '#031496',paddingTop:'8px',fontWeight: 'bold',fontSize:'15px' }}>VITAL STATS</Typography>
           
            <Link href="#" underline="hover" sx={{marginTop:'1rem',marginLeft:'1rem'}}>Low Stock Items: 0</Link>
            <Link href="#" underline="hover" sx={{marginTop:'1rem',marginLeft:'1rem'}}>Unpaid Invoices: 0</Link>
            <Link href="#" underline="hover" sx={{marginTop:'1rem',marginLeft:'1rem'}}>Unpaid Purchases: 0</Link>
            <Link href="#" underline="hover" sx={{marginTop:'1rem',marginLeft:'1rem'}}>Overdue Invoices: 0</Link>
         
          </Card>
          


          {/* Search Box */}
          <Card style={styles.card}>
        <Typography variant="h6" sx={{display:'flex',width:'100%',textAlign:'center', color: '#031496',paddingTop:'8px',fontWeight: 'bold',fontSize:'15px' }}>QUICK SEARCH</Typography>
        <RadioGroup
          column
          onChange={handleRadioChange}
          style={{ marginTop: '-8px',marginLeft:'30px' }}
        >
          <FormControlLabel
            value="stock"
            control={<Radio size="small" />}
            label="Stock"
            style={{ marginRight: '16px' }}
          />
          <FormControlLabel
            value="serialNo"
            control={<Radio size="small" />}
            label="Serial No"
            style={{ marginRight: '16px' }}
          />
          <FormControlLabel
            value="invoice"
            control={<Radio size="small" />}
            label="Invoice"
            style={{ marginRight: '16px' }}
          />
          <FormControlLabel
            value="customer"
            control={<Radio size="small" />}
            label="Customer"
            style={{ marginRight: '25px' }}
          />
          <FormControlLabel
            value="supplier"
            control={<Radio size="small" />}
            label="Supplier"
            style={{ marginRight: '16px' }}
          />
        </RadioGroup>
        <Box style={styles.searchBox}>
          <TextField
            size="medium"
            placeholder={searchPlaceholder}
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
            InputProps={{
              endAdornment: (
                <IconButton
                  type="submit"
                  aria-label="search"
                  style={{ padding: '10px'}}
                >
                  <SearchIcon />
                </IconButton>
              ),
            }} 
          />
        </Box>
      </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessDashboard;
