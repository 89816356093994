import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CashBook from './ReportsList/CashBook';
import DayBook from './ReportsList/DayBook';
import CollectionSummary from './ReportsList/CollectionSummary';
import ItemLedger from './ReportsList/ItemLedger';
import StockSummary from './ReportsList/StockSummary';
import ItemProgress from './ReportsList/ItemProgress';
import ItemWiseSale from './ReportsList/ItemWiseSale';
import SaleTaxSummary from './ReportsList/SaleTaxSummary';
import SaleOrder from './ReportsList/SaleOrder';
import PartyWiseSale from './ReportsList/PartyWiseSale';
import ItemWisePurchase from './ReportsList/ItemWisePurchase';
import PurchaseTaxSummary from './ReportsList/PurchaseTaxSummary';
import PurchaseOrders from './ReportsList/PurchaseOrder';
// import CashBook from './ReportsList/CashBook';
// Add other components as needed

const ReportList = () => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const handleReportClick = (report) => {
    setSelectedReport(report);
  };

  const handleBackClick = () => {
    setSelectedReport(null);
  };

  const handleMouseEnter = (category) => {
    setHoveredCategory(category);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  const renderPopupOptions = (category) => {
    switch (category) {
      case 'Accounts':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleReportClick('CashBook')}>
              CashBook
            </div>
            <div className="popup-option-item" onClick={() => handleReportClick('DayBook')}>
              DayBook
            </div>
         
            <div className="popup-option-item" onClick={() => handleReportClick('CollectionSummary')}>
              CollectionSummary
            </div>
            
          </div>
        );
      case 'Inventory':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleReportClick('ItemLedger')}>
             ItemLedger
            </div>
            <div className="popup-option-item" onClick={() => handleReportClick('StockSummary')}>
             StockSummary
            </div>
            <div className="popup-option-item" onClick={() => handleReportClick('ItemProgress')}>
              ItemProgress
            </div>
          </div>
        );
        case 'Sale':
          return (
            <div className="popup-options">
              <div className="popup-option-item" onClick={() => handleReportClick('ItemwiseSale')}>
               ItemwiseSale
              </div>
              <div className="popup-option-item" onClick={() => handleReportClick('SaletaxSummary')}>
               SaletaxSummary
              </div>
              <div className="popup-option-item" onClick={() => handleReportClick('SaleOrder')}>
                SaleOrder
              </div>
            </div>
          );
          case 'Purchase':
          return (
            <div className="popup-options">
              <div className="popup-option-item" onClick={() => handleReportClick('PurchasewiseSale')}>
               PurchasewiseSale
              </div>
              <div className="popup-option-item" onClick={() => handleReportClick('PurchasetaxSummary')}>
              PurchasetaxSummary
              </div>
              <div className="popup-option-item" onClick={() => handleReportClick('PurchaseOrder')}>
              PurchaseOrder
              </div>
            </div>
          );
      // Add other categories and options here
      default:
        return null;
    }
  };

  const renderTitle = () => {
    switch (selectedReport) {
      case 'CashBook':
        return 'CashBook';
      case 'DayBook':
        return 'DayBook';
      case 'CollectionSummary':
        return 'CollectionSummary';
        case 'ItemLedger':
          return 'ItemLedger';
        case 'StockSummary':
          return 'StockSummary';
        case 'ItemProgress':
          return 'ItemProgress';
          case 'PurchasewiseSale':
          return 'PurchasewiseSale';
        case 'PurchasetaxSummary':
          return 'PurchasetaxSummary';
        case 'PurchaseOrder':
          return 'PurchaseOrder';
      // Add other cases here
      default:
        return '';
    }
  };

  return (
    <div className="container">
      {!selectedReport ? (
        <div className="favorites-content">
          <div className="options">
            {['Accounts', 'Inventory', 'Sale', 'Purchase', 'Customer', 'Suppliers', 'Expenses', 'Staff', ].map((category) => (
              <div
                key={category}
                className="option-item"
                onMouseEnter={() => handleMouseEnter(category)}
                onMouseLeave={handleMouseLeave}
              >
                <span>{category}</span> <ArrowForwardIosIcon className="arrow-icon" />
                {hoveredCategory === category && renderPopupOptions(category)}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="output-container">
          <div className="title-bar">
            <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
            <h2>{renderTitle()}</h2>
          </div>
          <div className="output-content">
            {selectedReport === 'CashBook' && <CashBook />}
            {selectedReport === 'DayBook' && <DayBook />}
            {selectedReport === 'CollectionSummary' && <CollectionSummary />}
            {selectedReport === 'ItemLedger' && <ItemLedger />}
            {selectedReport === 'StockSummary' && <StockSummary />}
            {selectedReport === 'ItemProgress' && <ItemProgress />}
            {selectedReport === 'ItemwiseSale' && <ItemWiseSale />}
            {selectedReport === 'SaletaxSummary' && <SaleTaxSummary />}
            {selectedReport === 'SaleOrder' && <SaleOrder />}
            {selectedReport === 'PurchasewiseSale' && <ItemWisePurchase />}
            {selectedReport === 'PurchasetaxSummary' && <PurchaseTaxSummary/>}
            {selectedReport === 'PurchaseOrder' && <PurchaseOrders />}


            {/* Render other components based on the selected report */}
          </div>
        </div>
      )}

      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          padding: 10px;
          align-items: stretch;
        }

        .favorites-content {
          font-family: 'Poppins', sans-serif;
          width: 30%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .option-item {
          position: relative;
          padding: 12px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .option-item:hover {
          background-color: #f0f0f0;
        }

        .arrow-icon {
          font-size: 16px;
          color: #888;
        }

        .popup-options {
          position: absolute;
          top: 0;
          left: 100%;
          margin-left: 10px;
          background-color: #fff;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          padding: 10px;
          z-index: 10;
        }

        .popup-option-item {
          padding: 8px 12px;
          cursor: pointer;
          transition: background-color 0.2s;
          white-space: nowrap;
        }

        .popup-option-item:hover {
          background-color: #e0e0e0;
        }

        .output-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .title-bar {
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-left: 5px;
        }

        .back-icon {
          cursor: pointer;
          font-size: 24px;
        }

        .output-content {
          flex-grow: 1;
          padding: 10px;
        }

        /* Media queries for responsive design */
        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .favorites-content,
          .output-content {
            width: 100%;
            height: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default ReportList;
