import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import {Favorite,AccountBalance,Receipt,Settings,Group,ArrowDropDown,} from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from '@mui/icons-material/Person';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';

// Define the getCurrentDateTime function to return an object with formatted time and date
const getCurrentDateTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const time = `${formattedHours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
  
  // Adjusted date format
  const options = { 
    weekday: 'long', 
    month: 'long', 
    day: 'numeric', 
    year: 'numeric' 
  };
  const date = now.toLocaleDateString('en-US', options);
  
  return { time, date };
};


const BusinessSidebar = ({ activeNav, setActiveTab, activeTab }) => {
  const [isMoreDropdownOpen, setIsMoreDropdownOpen] = useState(false);
  const [isActivityDropdownOpen, setIsActivityDropdownOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(getCurrentDateTime().time);
  const [currentDate, setCurrentDate] = useState(getCurrentDateTime().date);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const { time, date } = getCurrentDateTime();
      setCurrentTime(time);
      setCurrentDate(date);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const renderSidebarContent = () => (
    <>
      <SidebarItem
        label="Dashboard"
        icon={<DashboardIcon />}
        isActive={activeTab === 'BusinessDashboard'}
        onClick={() => setActiveTab('BusinessDashboard')}
      />
      <SidebarItem
        label="Sale"
        icon={<TrendingUpIcon />}
        isActive={activeTab === 'Sale'}
        onClick={() => setActiveTab('Sale')}
      />
      <SidebarItem
        label="Purchase"
        icon={<ShopTwoIcon />}
        isActive={activeTab === 'Purchase'}
        onClick={() => setActiveTab('Purchase')}
      />
      <SidebarItem
        label="Client"
        icon={<PersonIcon />}
        isActive={activeTab === 'customer'}
        onClick={() => setActiveTab('customer')}
      />
      <SidebarItem
        label="Staff"
        icon={<ManageAccountsIcon />}
        isActive={activeTab === 'Staff'}
        onClick={() => setActiveTab('Staff')}
      />
      <SidebarItem
        label="Expense"
        icon={<EventNoteIcon />}
        isActive={activeTab === 'Expense'}
        onClick={() => setActiveTab('Expense')}
      />
      <SidebarItem
        label="Inventory"
        icon={<InventoryIcon />}
        isActive={activeTab === 'Inventory'}
        onClick={() => setActiveTab('Inventory')}
      />
      <SidebarItem
        label="Service"
        icon={<InventoryIcon />}
        isActive={activeTab === 'Service'}
        onClick={() => setActiveTab('Service')}
      />
      <SidebarItem
        label="Products"
        icon={<ShoppingBagIcon />}
        isActive={activeTab === 'Masters'}
        onClick={() => setActiveTab('Masters')}
      />
      <SidebarItem
        label="Report List"
        icon={<Receipt />}
        isActive={activeTab === 'ReportList'}
        onClick={() => setActiveTab('ReportList')}
      />
    </>
  );

  return (
    <Box sx={styles.sidebarContainer}>
      {renderSidebarContent()}
      <Box sx={styles.dateTimeContainer}>
        <Typography sx={styles.timeText}>{currentTime}</Typography>
        <Typography sx={styles.dateText}>{currentDate}</Typography>
      </Box>
    </Box>
  );
};

const SidebarItem = ({ label, icon, isActive, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      ...styles.sidebarItem,
      ...(isActive ? styles.sidebarItemActive : {}),
    }}
  >
    <Box sx={styles.iconContainer}>{icon}</Box>
    <Typography sx={styles.label}>{label}</Typography>
  </Box>
);

const styles = {
  sidebarContainer: {
    width: '250px',
    padding: '10px 25px 10px 15px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: '#225954',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
},
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.35)',
      backdropFilter: 'blur(9px)',
      WebkitBackdropFilter: 'blur(9px)',
      borderRadius: '20px',
    },
  },
  sidebarItemActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
    backdropFilter: 'blur(9px)',
    WebkitBackdropFilter: 'blur(9px)',
    borderRadius: '20px',
    fontWeight: 'bold',
    color: 'white',
  },
  iconContainer: {
    marginRight: '10px',
  },
  label: {
    fontWeight: 'bold',
  },
  dateTimeContainer: {
    marginTop: 'auto', // Pushes the date and time to the bottom
    paddingTop: '20px',
    borderTop: '1px solid rgba(255, 255, 255, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timeText: {
    color: 'white',
    fontWeight: 'bold',
  },
  dateText: {
    color: 'white',
    fontWeight: 'bold',
    marginTop: '5px', // Add some space between time and date
  },
};

export default BusinessSidebar;
