import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AddGroup = () => {
  const [groupName, setGroupName] = useState('');
  const [hsnCode, setHsnCode] = useState('');
  const [cgst, setCgst] = useState('');
  const [sgst, setSgst] = useState('');
  const [igst, setIgst] = useState('');
  const [cess, setCess] = useState('');
  const [groupData, setGroupData] = useState([]);

  const handleCgstChange = (e) => {
    const cgstValue = e.target.value;
    setCgst(cgstValue);

    // Reflect the same value in SGST
    setSgst(cgstValue);

    // Calculate IGST as the sum of CGST and SGST
    setIgst(Number(cgstValue) + Number(cgstValue));
  };

  const handleSave = () => {
    const newData = { groupName, hsnCode, cgst, sgst, igst, cess };
    setGroupData([...groupData, newData]);

    // Reset the form fields after saving
    setGroupName('');
    setHsnCode('');
    setCgst('');
    setSgst('');
    setIgst('');
    setCess('');
  };

  const handleEdit = (index) => {
    const groupToEdit = groupData[index];
    if (groupToEdit) {
      setGroupName(groupToEdit.groupName);
      setHsnCode(groupToEdit.hsnCode);
      setCgst(groupToEdit.cgst);
      setSgst(groupToEdit.sgst);
      setIgst(groupToEdit.igst);
      setCess(groupToEdit.cess);
      setGroupData(groupData.filter((_, i) => i !== index));
    }
  };

  const handleDelete = (index) => {
    setGroupData(groupData.filter((_, i) => i !== index));
  };

  return (
    <div style={styles.container}>
      <h2>Group</h2>

      {/* Add Group Section with Fieldset and Legend */}
      <fieldset style={styles.fieldset}>
        <legend style={styles.legend}>Add Group</legend>
        <div style={styles.formContainer}>
          {/* Row 1: HSN/SAC Code and Group Name */}
          <div style={styles.formRow}>
          <TextField
              label={<span className="required-label">Group Name </span>}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              variant="outlined"
              size="small"
              style={styles.textField}
              required
            />
            <TextField
              label="HSN / SAC Code"
              value={hsnCode}
              onChange={(e) => setHsnCode(e.target.value)}
              variant="outlined"
              size="small"
              style={styles.textField}
            />
          </div>
           {/* Row 2: SGST and CGST */}
          <div style={styles.formRow}>
          <TextField
              label="CGST (%)"
              type="number"
              value={cgst}
              onChange={handleCgstChange}
              variant="outlined"
              size="small"
              style={styles.textField}
            />
            <TextField
              label="SGST (%)"
              type="number"
              value={sgst}
              variant="outlined"
              size="small"
              style={styles.textField}
              disabled
            />
          </div>
     {/* Row 3: Cess and IGST */}
          <div style={styles.formRow}>
          <TextField
              label="IGST (%)"
              type="number"
              value={igst}
              variant="outlined"
              size="small"
              style={styles.textField}
              disabled
            />
            <TextField
              label="Cess (%)"
              type="number"
              value={cess}
              onChange={(e) => setCess(e.target.value)}
              variant="outlined"
              size="small"
              style={styles.textField}
            />
           </div>

          <button onClick={handleSave} style={styles.saveButton}>
            Save
          </button>
        </div>
      </fieldset>

      {/* Existing Data Section with Fieldset and Legend */}
      <fieldset style={styles.fieldset}>
        <legend style={styles.legend}>Existing Data</legend>
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>S. No.</th>
                <th style={styles.tableHeader}>Group Name</th>
                <th style={styles.tableHeader}>HSN/SAC Code</th>
                <th style={styles.tableHeader}>CGST (%)</th>
                <th style={styles.tableHeader}>SGST (%)</th>
                <th style={styles.tableHeader}>IGST (%)</th>
                <th style={styles.tableHeader}>Cess (%)</th>
                <th style={styles.tableHeader}>Action</th>
              </tr>
            </thead>
            <tbody>
              {groupData.map((row, index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{index + 1}</td>
                  <td style={styles.tableCell}>{row.groupName}</td>
                  <td style={styles.tableCell}>{row.hsnCode}</td>
                  <td style={styles.tableCell}>{row.cgst}</td>
                  <td style={styles.tableCell}>{row.sgst}</td>
                  <td style={styles.tableCell}>{row.igst}</td>
                  <td style={styles.tableCell}>{row.cess}</td>
                  <td style={styles.tableCell}>
                    <IconButton style={styles.editiconButton} onClick={() => handleEdit(index)} aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton style={styles.deleteiconButton} onClick={() => handleDelete(index)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
};

const styles = {
  container: {
    padding: '5px 20px 5px 20px',
    width: '800px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    fontFamily: 'Poppins',
    margin: '20px 0 0 300px',
  },
  fieldset: {
    border: '1px solid #bfbfbf',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '30px',
    backgroundColor: '#ffffff',
  },
  legend: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    padding: '0 10px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  textField: {
    width: '48%',
  },
  saveButton: {
    width: '150px',
    padding: '8px 26px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#0075fd',
    borderRadius: '10px',
    boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
    display: 'block',
    margin: '10px 0 0 600px',
  },
  tableContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    border: '1px solid #ddd',
    padding: '5px',
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
  },
  editiconButton: {
    padding: '4px',
    color: '#05b554',
  },
  deleteiconButton: {
    padding: '4px',
    color: '#f2392c',
  },
};

export default AddGroup;
