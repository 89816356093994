import React, { useState } from 'react';
import {
    Box, Typography, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Grid
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { styled } from '@mui/material/styles';

// Styled component for Claymorphism effect
const ClaymorphicBox = styled(Box)(({ theme }) => ({
  background: '#e0e5ec',
  boxShadow: '8px 8px 16px #a3b1c6, -8px -8px 16px #ffffff',
  borderRadius: '15px',
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
}));

const DayBook = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const data = [
        // Example data
        { date: '20-Aug-2024', number: 1, voucher: 'V001', party: 'ABC Corp', amount: 1000, notes: 'Payment received' },
        { date: '20-Aug-2024', number: 2, voucher: 'V002', party: 'XYZ Ltd', amount: 2000, notes: 'Invoice paid' },
        // Add more data here
    ];

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Day Book");
        XLSX.writeFile(workbook, "DayBook.xlsx");
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text("Day Book", 20, 10);
        doc.autoTable({
            head: [['Voucher', 'Date', 'Number', 'Party', 'Amount', 'Notes']],
            body: data.map(row => [row.voucher, row.date, row.number, row.party, row.amount, row.notes]),
        });
        doc.save('DayBook.pdf');
    };

    return (
        <ClaymorphicBox>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6">Day Book</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" color="textSecondary">
                        Period: 20-Aug-2024 To 20-Aug-2024
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={exportToExcel}>
                            Export Excel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={exportToPDF}>
                            Export PDF
                        </Button>
                    </Grid>
                    
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Today"
                                value={selectedDate}
                                onChange={(newValue) => setSelectedDate(newValue)}
                                renderInput={(params) => (
                                    <Button {...params} variant="contained" color="primary">
                                        {params.inputProps.value}
                                    </Button>
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ mt: 3, background: '#ffffff', boxShadow: 'none', borderRadius: '15px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Voucher</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Party</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.voucher}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.number}</TableCell>
                                <TableCell>{row.party}</TableCell>
                                <TableCell>{row.amount}</TableCell>
                                <TableCell>{row.notes}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </ClaymorphicBox>
    );
};

export default DayBook;
