import React, { useState } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExcelIcon from "@mui/icons-material/InsertDriveFile";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

// Claymorphism styled component
const ClayBox = styled(Box)({
  padding: "1rem",
  borderRadius: "20px",
  boxShadow: "8px 8px 16px #babecc, -8px -8px 16px #ffffff",
  background: "#f0f0f3",
  marginBottom: "20px",
});

const ClayTableContainer = styled(Box)({
  borderRadius: "15px",
  boxShadow: "8px 8px 16px #babecc, -8px -8px 16px #ffffff",
  background: "#ffffff",
  overflowX: "auto",
  marginTop: "20px",
});

const CashBook = () => {
  const [party, setParty] = useState("");
  const [category, setCategory] = useState(""); // State for category selection
  const [showTable, setShowTable] = useState(false);

  const handlePartyChange = (event) => {
    setParty(event.target.value);
    setShowTable(true);  // Show the table when a party is selected
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  // Example data
  const data = [
    { number: 1, date: '2024-08-01', billNo: 'B123', billDate: '2024-08-01', voucher: 'V123', party: 'Party A', toReceive: 100, toPay: 0, balance: 100, notes: '' },
    // Add more rows as needed
  ];

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Ledger Report', 20, 10);
    doc.autoTable({
      head: [['Number', 'Date', 'Bill No', 'Bill Date', 'Voucher', 'Party', 'To Receive', 'To Pay', 'Balance', 'Notes']],
      body: data.map(row => Object.values(row)),
    });
    doc.save('ledger-report.pdf');
  };

  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Ledger');
    XLSX.writeFile(workbook, 'ledger-report.xlsx');
  };

  return (
    <ClayBox sx={{ maxWidth: 900, margin: "0 auto" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <FormControl fullWidth sx={{ maxWidth: 300 }}>
          <InputLabel>Select Party</InputLabel>
          <Select value={party} onChange={handlePartyChange} label="Select Party">
            <MenuItem value={"adjustment_ac"}>Adjustment A/c</MenuItem>
            <MenuItem value={"cash_ac"}>Cash A/c</MenuItem>
            <MenuItem value={"car_ac"}>Car A/c</MenuItem>
            {/* Add more options as needed */}
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="outlined"
            startIcon={<ExcelIcon />}
            onClick={exportExcel}
          >
            Export Excel
          </Button>
          <Button
            variant="outlined"
            startIcon={<PictureAsPdfIcon />}
            sx={{ marginLeft: "8px" }}
            onClick={exportPDF}
          >
            Export PDF
          </Button>
        </Box>
      </Box>

      {party && (
        <Typography sx={{ marginTop: "16px", marginBottom: "16px", textAlign: "center" }}>
          Period: 31-Jul-2024 to 30-Aug-2024
        </Typography>
      )}

      {showTable && (
        <ClayTableContainer>
          <Box sx={{ padding: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Category</InputLabel>
              <Select value={category} onChange={handleCategoryChange} label="Select Category">
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"category1"}>Category 1</MenuItem>
                <MenuItem value={"category2"}>Category 2</MenuItem>
                {/* Add more categories as needed */}
              </Select>
            </FormControl>
          </Box>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#f0f0f3" }}>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Number</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Date</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Bill No</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Bill Date</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Voucher</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Party</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>To Receive</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>To Pay</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Balance</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>Notes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.number}>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.number}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.date}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.billNo}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.billDate}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.voucher}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.party}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.toReceive}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.toPay}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.balance}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{row.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ClayTableContainer>
      )}
    </ClayBox>
  );
};

export default CashBook;
