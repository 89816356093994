import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  IconButton
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { SaveAlt as ExportExcelIcon, PictureAsPdf as ExportPDFIcon, Print as PrintIcon, FilterList } from '@mui/icons-material';

// Claymorphism style
const claymorphismStyle = {
  boxShadow: '9px 9px 16px rgba(0, 0, 0, 0.2), -9px -9px 16px rgba(255, 255, 255, 0.8)',
  background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
  borderRadius: '20px',
  padding: '20px',
};

// Sample data for testing (replace with backend data once available)
const sampleData = [
  { number: 1, date: '01-Aug-2024', voucher: 'Voucher001', party: 'Party A', amount: '$1000', status: 'Completed' },
  { number: 2, date: '15-Aug-2024', voucher: 'Voucher002', party: 'Party B', amount: '$1500', status: 'Pending' },
  // Add more sample data as needed
];

const SaleOrder = () => {
  const [dateRange, setDateRange] = useState(dayjs());
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from backend (replace URL with actual API endpoint)
    const fetchData = async () => {
      try {
        // Uncomment and update this part when your backend is ready
        // const response = await fetch('http://your-backend-api/sale-orders');
        // const data = await response.json();
        // setRows(data);

        // For now, use sample data
        setRows(sampleData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Format the date to MM/DD/YY
  const formatDate = (date) => dayjs(date).format('MM/DD/YY');

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SaleOrder');
    XLSX.writeFile(workbook, 'SaleOrder.xlsx');
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.text('Sale Order Report', 20, 10);
    doc.autoTable({
      head: [['Number', 'Date', 'Voucher', 'Party', 'Amount', 'Status']],
      body: rows.map((row) => [row.number, row.date, row.voucher, row.party, row.amount, row.status]),
    });
    doc.save('SaleOrder.pdf');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Paper style={claymorphismStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
          <Typography variant="h5" gutterBottom>
            Sale Order
          </Typography>
          <Box display="flex" justifyContent="flex-end" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
            <Button variant="contained" onClick={handleExportExcel} startIcon={<ExportExcelIcon />} sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}>
              Export Excel
            </Button>
            <Button variant="contained" onClick={handleExportPDF} startIcon={<ExportPDFIcon />}>
              Export PDF
            </Button>
            <IconButton>
              <PrintIcon />
            </IconButton>
            <IconButton>
              <FilterList />
            </IconButton>
          </Box>
        </Box>

        <Typography variant="body1" gutterBottom>
          Period: {formatDate(dateRange.startDate)} to {formatDate(dateRange.endDate)}
        </Typography>

        {/* Date Picker */}
        <Box display="flex" justifyContent="flex-end" mb={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year', 'month', 'day']}  // Displaying day, month, and year
              label={dateRange.format('MMM, YYYY')}
              value={dateRange}
              onChange={(newValue) => setDateRange(newValue)}
              format="MM/DD/YY"
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>
        </Box>

        {/* Table */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Number</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Voucher</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Party</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.number}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.voucher}</TableCell>
                  <TableCell>{row.party}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default SaleOrder;
