import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Snackbar,
  Alert
} from '@mui/material';
import { CurrencyRupee } from '@mui/icons-material';
import BankNames from '../BusinessProfile/BankNames'; // Import the BankNames list
import { states } from '../BusinessProfile/States';

const SupplierForm = () => {
  // State hooks for form fields
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccountNo, setBankAccountNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [panNo, setPanNo] = useState('');
  const [gstin, setGstin] = useState('');
  const [accountState, setAccountState] = useState('');
  const [accountType, setAccountType] = useState('Debit');
  const [openingBalance, setOpeningBalance] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactNo, setContactNo] = useState('');

  const [successMessage, setSuccessMessage] = useState('');

  const handleSave = () => {
    if ( !companyName || !city || !state ) {
      alert("Please fill all required fields.");
      return;
    }

    const supplier = {
      companyName,
      address,
      city,
      state,
      pinCode,
      country,
      email,
      phoneNo,
      bankName,
      bankAccountNo,
      ifscCode,
      panNo,
      gstin,
      accountState,
      accountType,
      openingBalance,
      contactPerson,
      contactNo,
    };

    const storedSuppliers = JSON.parse(localStorage.getItem('suppliers')) || [];
    storedSuppliers.push(supplier);
    localStorage.setItem('suppliers', JSON.stringify(storedSuppliers));

    // Reset form fields after save
    setCompanyName('');
    setAddress('');
    setCity('');
    setState('');
    setPinCode('');
    setCountry('');
    setEmail('');
    setPhoneNo('');
    setBankName('');
    setBankAccountNo('');
    setIfscCode('');
    setPanNo('');
    setGstin('');
    setAccountState('');
    setAccountType('Debit');
    setOpeningBalance('');
    setContactPerson('');
    setContactNo('');

    // Show success message
    setSuccessMessage('Supplier saved successfully!');
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: '1rem auto',
        padding: '1rem',
        backgroundColor: '#f1f1f1',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        fontFamily: 'Poppins',
      }}
    >
      <form>
        <Grid container spacing={4}>

          {/* Supplier Details Section */}
          <Grid item xs={12} md={4}>
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginBottom: '1rem'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Supplier Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Company Name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="City"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                    <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    required
                    >
                    <InputLabel>State</InputLabel>
                    <Select
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        label="State"
                    >
                        <MenuItem value="">
                        <em>None</em>
                        </MenuItem>
                        {states.map((stateOption) => (
                        <MenuItem
                            key={stateOption.id}
                            value={stateOption.name}
                        >
                            {stateOption.name}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Pin Code"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Country"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Phone No."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    type="tel"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
              </Grid>
            </fieldset>

          </Grid>

          {/* Tax No. Section */}
          <Grid item xs={12} md={4}>
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginBottom: '1rem'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Tax No.
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="PAN No."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={panNo}
                    onChange={(e) => setPanNo(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="GSTIN"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={gstin}
                    onChange={(e) => setGstin(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
            </fieldset>

            {/* Account Details Section */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginBottom: '1rem'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Account Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <InputLabel>Account State</InputLabel>
                    <Select
                      value={accountState}
                      onChange={(e) => setAccountState(e.target.value)}
                      label="Account State"
                      required
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <InputLabel>Account Type</InputLabel>
                    <Select
                      value={accountType}
                      onChange={(e) => setAccountType(e.target.value)}
                      label="Account Type"
                      required
                    >
                      <MenuItem value="Debit">Debit</MenuItem>
                      <MenuItem value="Credit">Credit</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Opening Balance"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={openingBalance}
                    onChange={(e) => setOpeningBalance(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>

          {/* Contact Details Section */}
          <Grid item xs={12} md={4}>

            {/* Bank Details Section */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginBottom: '1rem'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Bank Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <InputLabel>Bank Name</InputLabel>
                    <Select
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      label="Bank Name"
                      required
                    >
                      {BankNames.map((bank, index) => (
                        <MenuItem key={index} value={bank}>
                          {bank}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Bank A/c No."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={bankAccountNo}
                    onChange={(e) => setBankAccountNo(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="IFSC Code"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={ifscCode}
                    onChange={(e) => setIfscCode(e.target.value)}
                    required
                  />
                </Grid>

              </Grid>
            </fieldset>

            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1.5rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginBottom: '1rem'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                Contact Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Contact Person"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Contact No."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    type="tel"
                    inputProps={{ maxLength: 10 }}
                    required
                  />
                </Grid>

                <Grid item xs={12} style={{ marginTop: '0.1rem' }}>
                <TextField
                  label="Remark/Note"
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                />
              </Grid>
              </Grid>
            </fieldset>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  padding: '8px 20px',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: '#0075fd',
                  borderRadius: '10px',
                  boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
                  float: 'right',
                  marginTop: '10px',
                }}
              >
                Save
              </Button>
            </Box>
      </form>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SupplierForm;