import React from 'react';
import { Grid, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { Avatar } from '@mui/material';
import LaptopIcon from '@mui/icons-material/Laptop';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const SaleInvoice = () => {
  // Dummy data
  const orderDetails = {
    orderNumber: 'INV123456',
    orderDate: '2024-08-31',
    placeOfSupply: 'Chennai, Tamil Nadu',
    customerName: 'John Doe',
    totals: {
      totalItems: 1000,
      netAmount: 1200
    },
    items: [
      {
        item: 'Laptop XYZ',
        pcs: 2,
        rate: 500,
        tax: 18
      },
      {
        item: 'Mouse ABC',
        pcs: 5,
        rate: 50,
        tax: 18
      }
    ]
  };

  const cgstAmount = (orderDetails.totals.totalItems * orderDetails.items[0].tax / 2).toFixed(2);
  const sgstAmount = cgstAmount;
  const totalAmount = (orderDetails.totals.netAmount).toFixed(2);

  const downloadPDF = () => {
    html2canvas(document.querySelector('#invoice')).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('invoice.pdf');
    });
  };

  const downloadImage = () => {
    html2canvas(document.querySelector('#invoice')).then(canvas => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'invoice.png';
      link.click();
    });
  };

  return (
    <Box sx={{  maxWidth: 900, margin: 'auto'}}>
      <Grid container spacing={2} justifyContent="flex-end" >
        <IconButton onClick={downloadImage} color="primary" sx={{ mr: 1 }}>
          <DownloadIcon />
        </IconButton>
        <IconButton onClick={downloadPDF} color="secondary">
          <PictureAsPdfIcon />
        </IconButton>
      </Grid>

      <Paper elevation={3} sx={{ p: 3, borderRadius: 4, boxShadow: '12px 12px 24px rgba(0,0,0,0.15), -12px -12px 24px rgba(255,255,255,0.9)' }} id="invoice">
        <Grid container alignItems="center" sx={{ bgcolor: '#83decc', p: 2, borderRadius: 2, mb: 3 }}>
          <Grid item xs={1}>
            <Avatar sx={{ bgcolor: '#fff', width: 56, height: 56 }}>
              <LaptopIcon sx={{ color: '#83decc', fontSize: 40 }} />
            </Avatar>
          </Grid>
          <Grid item xs={11} textAlign="center">
            <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold', color: '#89djjj' }}>
              Sale Invoice 
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1"><strong>Sale Invoice No:</strong> {orderDetails.orderNumber}</Typography>
            <Typography variant="body1"><strong>Date of Sale Invoice:</strong> {orderDetails.orderDate}</Typography>
            <Typography variant="body1"><strong>Place of Supply:</strong> {orderDetails.placeOfSupply}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="body1"><strong>Bill To:</strong></Typography>
            <Typography variant="body1">{orderDetails.customerName}</Typography>
            <Typography variant="body1"><strong>Supply To:</strong></Typography>
            <Typography variant="body1">{orderDetails.customerName}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead sx={{ bgcolor: '#83decc' }}>
                <TableRow>
                  <TableCell><strong>#</strong></TableCell>
                  <TableCell><strong>Description</strong></TableCell>
                  <TableCell><strong>Qty</strong></TableCell>
                  <TableCell><strong>Unit</strong></TableCell>
                  <TableCell><strong>Rate/Unit</strong></TableCell>
                  <TableCell><strong>Taxable Value</strong></TableCell>
                  <TableCell><strong>CGST</strong></TableCell>
                  <TableCell><strong>SGST</strong></TableCell>
                  <TableCell><strong>Total</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderDetails.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.item}</TableCell>
                    <TableCell>{item.pcs}</TableCell>
                    <TableCell>Nos</TableCell>
                    <TableCell>₹{item.rate.toFixed(2)}</TableCell>
                    <TableCell>₹{(item.rate * item.pcs).toFixed(2)}</TableCell>
                    <TableCell>₹{cgstAmount} @{(item.tax / 2).toFixed(3)}%</TableCell>
                    <TableCell>₹{sgstAmount} @{(item.tax / 2).toFixed(3)}%</TableCell>
                    <TableCell>₹{totalAmount}</TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ bgcolor: '#83decc' }}>
                  <TableCell colSpan={5}></TableCell>
                  <TableCell><strong>Total (₹)</strong></TableCell>
                  <TableCell>₹{cgstAmount}</TableCell>
                  <TableCell>₹{sgstAmount}</TableCell>
                  <TableCell>₹{totalAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default SaleInvoice;
