import React, { useState } from 'react';
import lo from '../assets/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const BusinessNav = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); // Toggle sidebar visibility
  };

  return (
    <div style={styles.navbarContainer}>
      <div style={styles.logoContainer}>
        <img src={lo} alt="logo" style={styles.logo} />
      </div>

      <div style={styles.toggleButton} onClick={toggleSidebar}>
        {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
    </div>
  );
};

// Styles
const styles = {
  navbarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 25px',
    backgroundColor: '#499989',
    fontFamily: "'Poppins', sans-serif",
    position: 'relative',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '70px',
    width: '300px',
  },
  toggleButton: {
    display: 'none',
    cursor: 'pointer',
    fontSize: '30px',
  },
  '@media (max-width: 768px)': {
    toggleButton: {
      display: 'block',
    },
  },
};

export default BusinessNav;
