// Unit.js
export const units = [
    'Piece',
    'Kg',
    'Litre',
    'Meter',
    'Box',
    'Dozen',
    'Set',
    'Pack',
    'Bag',
    'Carton'
  ];
  