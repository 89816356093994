import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';
import i1 from '../assets/bma.jpg';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/dashboard')
    // if (!username || !password) {
    //   alert('Please fill in all required fields.');
    //   return;
    // }

    // Simple local storage check for login
    // const storedUsername = localStorage.getItem('username');
    // const storedPassword = localStorage.getItem('password');

    // if (username === storedUsername && password === storedPassword) {
    //   navigate('/dashboard'); // Navigate to the dashboard after successful login
    // } else {
    //   alert('Invalid username or password.');
    // }
  };

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '85%', md: '50%' },
        maxWidth: '800px',
        height:'400px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        margin: 'auto',
        mt: 20,
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center', // Center image vertically
          justifyContent: 'center', // Center image horizontally
          overflow: 'hidden',
        }}
      >
        <img src={i1} alt="Login" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>

      {/* Form Section */}
      <Box
        sx={{
          flex: 1,
          padding: { xs: '10px', md: '30px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
          Login to Your Account
        </Typography>

        {/* Username Field */}
        <TextField
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ marginBottom: '15px', maxWidth: '400px' }}
          required
        />

        {/* Password Field */}
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: '15px', maxWidth: '400px' }}
          required
        />

        <Button sx={{ backgroundColor: 'black', color: 'white' }} onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
