import React, { useState } from 'react';
import {
  Button, Grid, Typography, IconButton, TextField, Box, Menu, MenuItem, Container,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Styled component for Claymorphism effect
const ClaymorphicBox = styled(Box)(({ theme }) => ({
  background: '#e0e5ec',
  boxShadow: '8px 8px 16px #a3b1c6, -8px -8px 16px #ffffff',
  borderRadius: '15px',
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
}));

const sampleData = [
  { number: 'PO001', date: '01-Aug-2024', voucher: 'V001', party: 'Party A', amount: '$1000', status: 'Pending' },
  { number: 'PO002', date: '05-Aug-2024', voucher: 'V002', party: 'Party B', amount: '$2000', status: 'Completed' },
  { number: 'PO003', date: '10-Aug-2024', voucher: 'V003', party: 'Party C', amount: '$1500', status: 'Pending' },
];

const PurchaseOrders = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExcelExport = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Purchase Order');
    XLSX.writeFile(wb, 'PurchaseOrder.xlsx');
  };

  const handlePdfExport = () => {
    const doc = new jsPDF();
    doc.text('Purchase Order', 20, 10);

    autoTable(doc, {
      head: [['Number', 'Date', 'Voucher', 'Party', 'Amount', 'Status']],
      body: sampleData.map(row => [row.number, row.date, row.voucher, row.party, row.amount, row.status]),
    });

    doc.save('PurchaseOrder.pdf');
  };

  return (
    <Container>
      <ClaymorphicBox>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="span" sx={{ ml: 1 }}>
              Purchase Order
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              startIcon={<GetAppIcon />}
              onClick={handleExcelExport}
              sx={{ mr: 1 }}
            >
              Export Excel
            </Button>
            <Button
              variant="outlined"
              startIcon={<PictureAsPdfIcon />}
              onClick={handlePdfExport}
              sx={{ mr: 1 }}
            >
              Export PDF
            </Button>
            
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              MenuListProps={{ 'aria-labelledby': 'filter-button' }}
            >
              <MenuItem onClick={handleMenuClose}>All</MenuItem>
              <MenuItem onClick={handleMenuClose}>Pending</MenuItem>
              <MenuItem onClick={handleMenuClose}>Completed</MenuItem>
            </Menu>
          </Box>
        </Box>

        <Grid container alignItems="center" justifyContent="space-between" mb={3}>
          <Grid item>
            <Typography variant="body2">Period: 31-Jul-2024 To 30-Aug-2024</Typography>
            <Typography variant="body2">Status: All</Typography>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year', 'month']}
                label="Select Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            </LocalizationProvider>
          
          </Grid>
        </Grid>

        {/* Table Header */}
        <Grid container spacing={1} style={{ fontWeight: 'bold', borderBottom: '2px solid #e0e5ec', marginBottom: '10px' }}>
          <Grid item xs={2}>Number</Grid>
          <Grid item xs={2}>Date</Grid>
          <Grid item xs={2}>Voucher</Grid>
          <Grid item xs={2}>Party</Grid>
          <Grid item xs={2}>Amount</Grid>
          <Grid item xs={2}>Status</Grid>
        </Grid>

        {/* Table Rows - Sample Data */}
        {sampleData.map((row, index) => (
          <Grid container spacing={1} key={index} style={{ borderBottom: '1px solid #e0e5ec', padding: '10px 0' }}>
            <Grid item xs={2}>{row.number}</Grid>
            <Grid item xs={2}>{row.date}</Grid>
            <Grid item xs={2}>{row.voucher}</Grid>
            <Grid item xs={2}>{row.party}</Grid>
            <Grid item xs={2}>{row.amount}</Grid>
            <Grid item xs={2}>{row.status}</Grid>
          </Grid>
        ))}
      </ClaymorphicBox>
    </Container>
  );
};

export default PurchaseOrders;
