import React, { useState, useRef } from 'react';
import {
  TextField,
  Button,
  Autocomplete,
  Grid,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

const ExpenseForm = () => {
  const [expenseTypes] = useState([
    'Office Supplies',
    'Travel',
    'Meals & Entertainment',
    'Utilities',
    'Rent',
    'Marketing',
    'Insurance'
  ]);

  const [errors, setErrors] = useState({});
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const printRef = useRef(); // Reference to the print area

  const handleSave = (shouldPrint = false) => {
    // Clear previous errors
    setErrors({});

    // Collect form data
    const newExpense = {
      date: document.getElementById('date').value,
      paymentRefNo: document.getElementById('paymentRefNo').value,
      expenseType: document.getElementById('expenseType').value,
      paidBy: document.getElementById('paidBy').value,
      amount: document.getElementById('amount').value,
      paidTo: document.getElementById('paidTo').value,
      payMode: document.getElementById('payMode').value,
      remarks: document.getElementById('remarks').value
    };

    // Validate form
    const newErrors = {};
    if (!newExpense.expenseType) {
      newErrors.expenseType = 'Expense Type is required';
    }
    // Add more validation checks as needed

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Save data to localStorage
    const storedExpenses = JSON.parse(localStorage.getItem('expenses')) || [];
    const updatedExpenses = [...storedExpenses, newExpense];
    localStorage.setItem('expenses', JSON.stringify(updatedExpenses));

    console.log('Form data saved:', updatedExpenses);

    // Open success modal
    setOpenSuccessModal(true);

    if (shouldPrint) {
      // Inject the content into the hidden print area
      const printContent = `
        <div style="padding: 20px; font-family: Arial, sans-serif;">
          <h2>Expense Voucher</h2>
          <table border="1" cellspacing="0" cellpadding="10" style="width: 100%; border-collapse: collapse;">
            <tr><th>Date</th><td>${newExpense.date}</td></tr>
            <tr><th>Payment Ref. No.</th><td>${newExpense.paymentRefNo}</td></tr>
            <tr><th>Expense Type</th><td>${newExpense.expenseType}</td></tr>
            <tr><th>Paid By</th><td>${newExpense.paidBy}</td></tr>
            <tr><th>Amount</th><td>${newExpense.amount}</td></tr>
            <tr><th>Paid To</th><td>${newExpense.paidTo}</td></tr>
            <tr><th>Pay Mode</th><td>${newExpense.payMode}</td></tr>
            <tr><th>Remarks</th><td>${newExpense.remarks}</td></tr>
          </table>
        </div>
      `;
      printRef.current.innerHTML = printContent;

      // Save current body content and print only the specific area
      const originalContent = document.body.innerHTML;
      document.body.innerHTML = printRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContent;
      window.location.reload(); // Reload to reset the content
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false); // Just close the modal, form stays open
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        backgroundColor: 'white',
        padding: 3,
        boxShadow: 3,
        marginTop: 3,
        marginLeft:10,
        position: 'relative',
        fontFamily: 'Poppins',
        '@media (max-width: 600px)': {
          padding: 2,
          marginTop: 2,
        },
        '@media (min-width: 1200px)': {
          maxWidth: 'lg',
        },
      }}
    >
      <Box sx={{ paddingBottom: 3 }}>
        <h2>Add Expense</h2>
      </Box>

      <fieldset style={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px', marginBottom: '16px' }}>
        <legend style={{ padding: '0 10px', fontSize: '1.2rem', fontWeight: 'bold' }}>Expense Details</legend>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="date"
              label="Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="paymentRefNo"
              label="Payment Ref. No."
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="expenseType"
              options={expenseTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Expense Type"
                  fullWidth
                  error={!!errors.expenseType}
                  helperText={errors.expenseType}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="paidBy"
              label="Paid By"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="amount"
              label="Amount"
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="paidTo"
              label="Paid To"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="payMode"
              label="Pay Mode"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="remarks"
              label="Remarks"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </fieldset>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        marginTop: 2,
        '@media (min-width: 600px)': {
          flexDirection: 'row',
          justifyContent: 'center',
        },
      }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#0075fd',
            borderRadius: '10px',
            boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
            '@media (max-width: 600px)': {
              fontSize: '0.9rem',
            },
          }}
          onClick={() => handleSave(true)}
        >
          Save and Print
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#0075fd',
            borderRadius: '10px',
            boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
            '@media (max-width: 600px)': {
              fontSize: '0.9rem',
            },
          }}
          onClick={() => handleSave(false)}
        >
          Save
        </Button>
      </Box>

      {/* Hidden container for printing */}
      <div ref={printRef} style={{ display: 'none' }}></div>

      {/* Success Modal */}
      <Dialog open={openSuccessModal} onClose={handleCloseSuccessModal}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <p>Data saved successfully!</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ExpenseForm;
