import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// The AddUnit component
const AddUnit = () => {
  const defaultUnits = [
    { id: 1, name: 'Kg' },
    { id: 2, name: 'g' },
    { id: 3, name: 'mg' },
    { id: 4, name: 'Ltr' },
    { id: 5, name: 'mL' },
    { id: 6, name: 'oz' },
    { id: 7, name: 'lb' },
    { id: 8, name: 'pcs' },
    { id: 9, name: 'pkg' },
    { id: 10, name: 'doz' },
  ];

  const [unitName, setUnitName] = useState('');
  const [units, setUnits] = useState([]);
  const [error, setError] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  // Load units from localStorage or use default values when component mounts
  useEffect(() => {
    try {
      const storedUnits = JSON.parse(localStorage.getItem('units'));
      if (storedUnits) {
        // Merge stored units with default units to ensure default units are always shown
        const mergedUnits = [...defaultUnits, ...storedUnits.filter(unit => !defaultUnits.some(defaultUnit => defaultUnit.name === unit.name))];
        setUnits(mergedUnits);
      } else {
        setUnits(defaultUnits);
      }
    } catch (error) {
      console.error('Error loading units from localStorage:', error);
      setUnits(defaultUnits); // Fall back to default units if there's an error
    }
  }, []);

  // Save units to localStorage whenever units state changes
  useEffect(() => {
    try {
      localStorage.setItem('units', JSON.stringify(units.filter(unit => !defaultUnits.some(defaultUnit => defaultUnit.name === unit.name))));
    } catch (error) {
      console.error('Error saving units to localStorage:', error);
    }
  }, [units]);

  const handleSave = () => {
    if (unitName.trim() === '') {
      setError('Unit Name is required');
      return;
    }

    // Check if the unit already exists in the units array
    if (units.some(unit => unit.name.toLowerCase() === unitName.toLowerCase())) {
      setError('Unit already exists');
      return;
    }

    if (editIndex !== null) {
      // Update existing unit
      const updatedUnits = [...units];
      updatedUnits[editIndex] = {
        ...updatedUnits[editIndex],
        name: unitName
      };
      setUnits(updatedUnits);
      setEditIndex(null);
    } else {
      // Add new unit
      const newUnit = {
        id: units.length ? Math.max(...units.map(unit => unit.id)) + 1 : 1,
        name: unitName,
      };
      setUnits([...units, newUnit]);
    }

    setUnitName('');
    setError('');
  };

  const handleEdit = (index) => {
    setUnitName(units[index].name);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    setUnits(units.filter((_, i) => i !== index));
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2>Units</h2>
      </div>
      <fieldset style={styles.fieldset}>
        <legend style={styles.legend}>Add Unit</legend>
        <div style={styles.formContainer}>
          <TextField
            label={<span className="required-label">Unit Name *</span>}
            value={unitName}
            onChange={(e) => setUnitName(e.target.value)}
            variant="outlined"
            size="small"
            style={styles.textField}
            error={!!error}
            helperText={error}
          />
        </div>
        <button onClick={handleSave} style={styles.saveButton}>
          {editIndex !== null ? 'Update' : 'Save'}
        </button>
      </fieldset>

      <fieldset style={styles.fieldset}>
        <legend style={styles.legend}>Existing Data</legend>
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>S. No.</th>
                <th style={styles.tableHeader}>Unit Name</th>
                <th style={styles.tableHeader}>Action</th>
              </tr>
            </thead>
            <tbody>
              {units.map((unit, index) => (
                <tr key={unit.id}>
                  <td style={styles.tableCell}>{index + 1}</td>
                  <td style={styles.tableCell}>{unit.name}</td>
                  <td style={styles.tableCell}>
                    <IconButton
                      style={styles.editiconButton}
                      onClick={() => handleEdit(index)}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      style={styles.deleteiconButton}
                      onClick={() => handleDelete(index)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
}

const styles = {
  container: {
    padding: '5px 20px',
    width: '400px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    fontFamily: 'Poppins',
    margin: '20px auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldset: {
    border: '1px solid #bfbfbf',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '20px',
    backgroundColor: '#ffffff',
  },
  legend: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    padding: '0 10px',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  textField: {
    width: '100%',
  },
  saveButton: {
    padding: '8px 20px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#0075fd',
    borderRadius: '10px',
    boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
    float: 'right',
    marginTop: '10px',
  },
  tableContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    border: '1px solid #ddd',
    padding: '8px',
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
  },
  iconButton: {
    margin: '0 4px',
  },
  editiconButton: {
    color: 'blue',
  },
  deleteiconButton: {
    color: 'red',
  },
};

export default AddUnit;
