import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Add, Edit, Delete, Search, Save } from '@mui/icons-material';
import Expenceform from './Expenceform'; // Make sure to create an Expenceform component

const ExpensesTable = () => {
  const [expensesList, setExpensesList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editedExpense, setEditedExpense] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const storedExpenses = JSON.parse(localStorage.getItem('expenses')) || [];
    setExpensesList(storedExpenses);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAdd = () => {
    setIsFormOpen(true);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedExpense({ ...expensesList[index] });
  };

  const handleSave = () => {
    const updatedExpensesList = [...expensesList];
    updatedExpensesList[editIndex] = editedExpense;
    setExpensesList(updatedExpensesList);
    localStorage.setItem('expenses', JSON.stringify(updatedExpensesList));
    setEditIndex(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedExpense((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const updatedExpensesList = expensesList.filter((_, i) => i !== deleteIndex);
      setExpensesList(updatedExpensesList);
      localStorage.setItem('expenses', JSON.stringify(updatedExpensesList));
    }
    handleDeleteClose();
  };

  const filteredExpensesList = expensesList.filter(
    (expense) =>
      expense.expenseType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      expense.amount.includes(searchTerm)
  );

  return (
    <Box sx={{ padding: '1rem', }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" mb={3}>
        <TextField
          label="Search Expenses"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
          sx={{ marginBottom: '1rem', width: '100%', maxWidth: '300px' }}
        />
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAdd}>
          Add Expense
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ height: '400px', overflowY: 'auto' }}>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>S. No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Date</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Type</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Amount</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Paid To</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Remarks</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Pay Mode</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Payment Ref. No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Paid By</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredExpensesList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  No expenses available
                </TableCell>
              </TableRow>
            ) : (
              filteredExpensesList.map((expense, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="date"
                        value={editedExpense.date}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.date
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="expenseType"
                        value={editedExpense.expenseType}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.expenseType
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="amount"
                        value={editedExpense.amount}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.amount
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="paidTo"
                        value={editedExpense.paidTo}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.paidTo
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="remarks"
                        value={editedExpense.remarks}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.remarks
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="payMode"
                        value={editedExpense.payMode}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.payMode
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="paymentRefNo"
                        value={editedExpense.paymentRefNo}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.paymentRefNo
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="paidBy"
                        value={editedExpense.paidBy}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      expense.paidBy
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <IconButton color="primary" onClick={handleSave}>
                        <Save />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton color="primary" onClick={() => handleEdit(index)}>
                          <Edit />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => handleDeleteOpen(index)}>
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={openDialog}
        onClose={handleDeleteClose}
        aria-labelledby="delete-confirmation-dialog"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this expense?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Expense Form Modal */}
      <Dialog
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="expense-form-dialog"
      >
        
        <DialogContent>
          <Expenceform
            expense={editIndex !== null ? editedExpense : {}}
            onSave={(expense) => {
              if (editIndex !== null) {
                handleSave();
              } else {
                const updatedExpensesList = [...expensesList, expense];
                setExpensesList(updatedExpensesList);
                localStorage.setItem('expenses', JSON.stringify(updatedExpensesList));
              }
              setIsFormOpen(false);
            }}
            onClose={() => setIsFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ExpensesTable;
