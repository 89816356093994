import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Typography,
  Checkbox,
  Snackbar,
  Alert
} from '@mui/material';
import { Person, UploadFile, Cancel, CurrencyRupee } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

const bloodGroups = [
  'A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'
];

const StaffForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState(null);
  const [joiningDate, setJoiningDate] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [designation, setDesignation] = useState('');
  const [department, setDepartment] = useState('');
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [address, setAddress] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [communication, setCommunication] = useState([]);
  const [salesCommission, setSalesCommission] = useState('no');
  const [remark, setRemark] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message


  useEffect(() => {
    if (location.state && location.state.staff) {
      const staff = location.state.staff;
      setJoiningDate(staff.joiningDate || '');
      setReferredBy(staff.referredBy || '');
      setDesignation(staff.designation || '');
      setDepartment(staff.department || '');
      setFullName(staff.fullName || '');
      setGender(staff.gender || '');
      setAddress(staff.address || '');
      setMobileNo(staff.mobileNo || '');
      setDateOfBirth(staff.dateOfBirth || '');
      setContactPerson(staff.contactPerson || '');
      setContactNo(staff.contactNo || '');
      setBloodGroup(staff.bloodGroup || '');
      setDocumentType(staff.documentType || '');
      setDocumentNumber(staff.documentNumber || '');
      setCommunication(staff.communication || []);
      setSalesCommission(staff.salesCommission || 'no');
      setRemark(staff.remark || '');
      setProfilePic(staff.profilePic || null);
      setEditIndex(location.state.editIndex);
    }
  }, [location.state]);

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setProfilePic(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveProfilePic = () => {
    setProfilePic(null);
  };

  const handleSave = () => {

    if (!designation || !department || !fullName || !address || !mobileNo ) {
      alert("Please fill all required fields.");
      return;
    }

    const staff = {
      joiningDate,
      referredBy,
      designation,
      department,
      fullName,
      gender,
      address,
      mobileNo,
      dateOfBirth,
      contactPerson,
      contactNo,
      bloodGroup,
      documentType,
      documentNumber,
      communication,
      salesCommission,
      remark,
      profilePic,
    };

    const storedStaff = JSON.parse(localStorage.getItem('staff')) || [];

    if (editIndex !== null) {
      storedStaff[editIndex] = staff;
    } else {
      storedStaff.push(staff);
    }

    localStorage.setItem('staff', JSON.stringify(storedStaff));
    
    setSnackbarMessage('Staff information saved successfully!');
    setOpenSnackbar(true);
    
    // Clear form after save
    setProfilePic(null);
    setJoiningDate('');
    setReferredBy('');
    setDesignation('');
    setDepartment('');
    setFullName('');
    setGender('');
    setAddress('');
    setMobileNo('');
    setDateOfBirth('');
    setContactPerson('');
    setContactNo('');
    setBloodGroup('');
    setDocumentType('');
    setDocumentNumber('');
    setCommunication([]);
    setSalesCommission('no');
    setRemark('');
    setEditIndex(null);

    // Show success message
    setSuccessMessage('Customer saved successfully!');
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        // margin: '.5rem auto 0 6rem',
        padding: '1rem',
        backgroundColor: '#f9f9f9',
        boxShadow: 10,
        borderRadius: '8px',
        fontFamily: 'Poppins',
        marginLeft:'20px',
        marginRight:'-250px'
      }}
    >
      <form>
        <Grid container spacing={4} >
          {/* First Column: Profile Picture */}
          <Grid item xs={12} md={3}>
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '.5rem',
                borderRadius: '8px',
                backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                  fontFamily: 'Poppins',
                }}
              >
                Staff Profile 
              </legend>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  sx={{
                    width: 90,
                    height: 90,
                    border: '2px dashed #ccc',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    marginBottom: '1rem',
                  }}
                >
                  {profilePic ? (
                    <img
                      src={profilePic}
                      alt="Profile"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Person sx={{ fontSize: 60, color: '#ccc' }} />
                  )}
                </Box>
                <Box display="flex" gap={1}>
                  <label htmlFor="upload-button">
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="upload-button"
                      type="file"
                      onChange={handleProfilePicChange}
                    />
                    <Button
                      variant="contained"
                      style={{ fontSize: '0.6rem' }}
                      component="span"
                      startIcon={<UploadFile />}
                    >
                      ADD
                    </Button>
                  </label>
                  {profilePic && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleRemoveProfilePic}
                      style={{ fontSize: '0.6rem' }}
                      startIcon={<Cancel />}
                    >
                      Clear
                    </Button>
                  )}
                </Box>
              </Box>
            </fieldset>
          </Grid>

          {/* Second Column: Staff and Personal Details */}
          <Grid item xs={12} md={5}>
            {/* Staff Information */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1rem',
                borderRadius: '8px',
                marginBottom: '1.5rem',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Staff Information
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <label>Joining Date</label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="date"
                    value={joiningDate}
                    onChange={(e) => setJoiningDate(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6} sx={{marginTop: '1.5rem'}}>
                  <TextField
                    label="Referred By"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={referredBy}
                    onChange={(e) => setReferredBy(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>Designation</InputLabel>
                    <Select
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      label="Designation"
                    >
                      <MenuItem value="Relationship Manager">Relationship Manager</MenuItem>
                      <MenuItem value="Administrator">Administrator</MenuItem>
                      <MenuItem value="Marketing Executive">Marketing Executive</MenuItem>
                      <MenuItem value="Manager">Manager</MenuItem>
                      <MenuItem value="Accountant">Accountant</MenuItem>
                      <MenuItem value="Clerk">Clerk</MenuItem>
                      <MenuItem value="Peon">Peon</MenuItem>
                      <MenuItem value="Office Boy">Office Boy</MenuItem>
                      <MenuItem value="Receptionist">Receptionist</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>Department</InputLabel>
                    <Select
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      label="Department"
                    >
                      <MenuItem value="Human">Human</MenuItem>
                      <MenuItem value="Sales">Sales</MenuItem>
                      <MenuItem value="Marketing">Marketing</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="Human Resources">Human Resources</MenuItem>
                      <MenuItem value="Administration">Administration</MenuItem>
                      <MenuItem value="Accounts">Accounts</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </fieldset>

            {/* Personal Information */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1rem',
                borderRadius: '8px',
                marginBottom: '1.5rem',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Personal Information
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Full Name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label>Gender :</label>
                  <RadioGroup
                    row
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                  </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    fullWidth
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6} sx={{marginTop: '1.5rem'}}>
                  <TextField
                    label="Mobile No."
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="tel"
                    value={mobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                    inputProps={{ pattern: "\\d{10}" }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                <label>Date of birth</label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>

          {/* Third Column: Emergency Contact, Identity Details, and Other Details */}
          <Grid item xs={12} md={4}>
            {/* Emergency Contact */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1rem',
                borderRadius: '8px',
                marginBottom: '1.5rem',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Emergency Contact
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Contact Person"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Contact No."
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="tel"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    inputProps={{ pattern: "\\d{10}" }}
                    disabled={!contactPerson}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>Blood Group</InputLabel>
                    <Select
                      value={bloodGroup}
                      onChange={(e) => setBloodGroup(e.target.value)}
                      label="Blood Group"
                    >
                      {bloodGroups.map((group) => (
                        <MenuItem key={group} value={group}>
                          {group}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </fieldset>

            {/* Identity Details */}
            <fieldset
              style={{
                border: '1px solid #bfbfbf',
                padding: '1rem',
                borderRadius: '8px',
                marginBottom: '1.5rem',
                 backgroundColor: '#fff'
              }}
            >
              <legend
                style={{
                  padding: '0 0.5rem',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                Identity Details
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>Document Type</InputLabel>
                    <Select
                      value={documentType}
                      onChange={(e) => setDocumentType(e.target.value)}
                      label="Document Type"
                    >
                      <MenuItem value="Aadhar">Aadhar</MenuItem>
                      <MenuItem value="PAN card">PAN card</MenuItem>
                      <MenuItem value="License">License</MenuItem>
                      <MenuItem value="Voter ID">Voter ID</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Document Number"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={documentNumber}
                    onChange={(e) => setDocumentNumber(e.target.value)}
                    disabled={!documentType}
                  />
                </Grid>
              </Grid>
            </fieldset>

            {/* Other Details */}
            <fieldset
                    style={{
                        border: '1px solid #bfbfbf',
                        padding: '1rem',
                        borderRadius: '8px',
                        marginBottom: '0.5rem',
                         backgroundColor: '#fff'
                    }}
                    >
                    <legend
                        style={{
                        padding: '0 0.5rem',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        color: 'black',
                        }}
                    >
                        Other Details
                    </legend>
                    <Grid container spacing={2}>
                        <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', alignItems: 'center' }}  // Align items in the center
                        >
                        <Typography style={{ marginRight: '10px' }}>Communication:</Typography> {/* Add margin */}
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={communication.includes('SMS')}
                                onChange={(e) =>
                                setCommunication((prev) =>
                                    e.target.checked
                                    ? [...prev, 'SMS']
                                    : prev.filter((item) => item !== 'SMS')
                                )
                                }
                            />
                            }
                            label="SMS"
                            style={{ marginRight: '10px' }}  // Add margin between checkboxes
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={communication.includes('Email')}
                                onChange={(e) =>
                                setCommunication((prev) =>
                                    e.target.checked
                                    ? [...prev, 'Email']
                                    : prev.filter((item) => item !== 'Email')
                                )
                                }
                            />
                            }
                            label="Email"
                        />
                        </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Typography style={{ marginRight: '10px' }}>Sales Commission:</Typography> {/* Add margin */}
                        <RadioGroup
                            row
                            value={salesCommission}
                            onChange={(e) => setSalesCommission(e.target.value)}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                        </Grid>

                        <Grid item xs={12}>
                        <TextField
                            label="Remark/Note"
                            fullWidth
                            variant="outlined"
                            size="small"
                            multiline
                            rows={2}
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                        </Grid>
                    </Grid>
                    </fieldset>

                </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  padding: '8px 20px',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: '#0075fd',
                  borderRadius: '10px',
                  boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
                  float: 'right',
                  marginTop: '10px',
                  width:'150px'
                }}
              >
                Save
              </Button>
            </Box>
      </form>
      {/* Success Message Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StaffForm;