import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SupplierForm from './SupplierForm';
import SupplierList from './SupplierList';
import PurchaseBill from './PurchaseBill';
import PurchaseBillTable from './PurchaseBillTable';
import PurchaseOrderForm from './PurchaseOrderForm';
import PurchaseOrderTable from './PurchaseOrderTable';
import PurchaseReturn from './PurchaseReturn';
import PurchaseReturnTable from './PurchaseReturnTable';

const Purchase = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [hoveredOption, setHoveredOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  const handleMouseEnter = (option) => {
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const renderPopupOptions = (option) => {
    switch (option) {
      case 'Purchase Bill':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add-purchase-bill')}>
              Add Purchase Bill
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('manage-purchase-bill')}>
              Search and Manage Purchase Bill
            </div>
          </div>
        );
      case 'Purchase Order':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add-purchase-order')}>
              Add Purchase Order
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('manage-purchase-order')}>
              Search and Manage Purchase Order
            </div>
          </div>
        );
      case 'Purchase Return':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add-purchase-return')}>
              Add Purchase Return
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('manage-purchase-return')}>
              Search and Manage Purchase Return
            </div>
          </div>
        );
      case 'Debit Note':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add-debit-note')}>
              Add Debit Note
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('manage-debit-note')}>
              Search and Manage Debit Note
            </div>
          </div>
        );
      case 'Credit Note':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add-credit-note')}>
              Add Credit Note
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('manage-credit-note')}>
              Search and Manage Credit Note
            </div>
          </div>
        );
      case 'Supplier':
        return (
          <div className="popup-options">
            <div className="popup-option-item" onClick={() => handleOptionClick('Add-supplier')}>
              Add Supplier
            </div>
            <div className="popup-option-item" onClick={() => handleOptionClick('manage-supplier')}>
              Search and Manage Supplier
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderTitle = () => {
    switch (selectedOption) {
      case 'Add-purchase-bill':
        return 'Add Purchase Bill';
      case 'manage-purchase-bill':
        return 'Search and Manage Purchase Bill';
      case 'Add-purchase-order':
        return 'Add Purchase Order';
      case 'manage-purchase-order':
        return 'Search and Manage Purchase Order';
      case 'Add-purchase-return':
        return 'Add Purchase Return';
      case 'manage-purchase-return':
        return 'Search and Manage Purchase Return';
      case 'Add-debit-note':
        return 'Add Debit Note';
      case 'manage-debit-note':
        return 'Search and Manage Debit Note';
      case 'Add-credit-note':
        return 'Add Credit Note';
      case 'manage-credit-note':
        return 'Search and Manage Credit Note';
      case 'Add-supplier':
        return 'Add Supplier';
      case 'manage-supplier':
        return 'Search and Manage Supplier';
      default:
        return '';
    }
  };

  return (
    <div className="container">
      {!selectedOption ? (
        <div className="favorites-content">
          <div className="options">
            {['Purchase Bill', 'Purchase Order', 'Purchase Return', 'Supplier'].map((option) => (
              <div
                key={option}
                className="option-item"
                onMouseEnter={() => handleMouseEnter(option)}
                onMouseLeave={handleMouseLeave}
              >
                <span>{option}</span> <ArrowForwardIosIcon className="arrow-icon" />
                {hoveredOption === option && renderPopupOptions(option)}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="output-container">
          <div className="title-bar">
            <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
            <h2>{renderTitle()}</h2>
          </div>
          <div className="output-content">
            {selectedOption === 'Add-purchase-bill' && <PurchaseBill />}
            {selectedOption === 'manage-purchase-bill' && <PurchaseBillTable />}
            {selectedOption === 'Add-purchase-order' && <PurchaseOrderForm />}
            {selectedOption === 'manage-purchase-order' && <PurchaseOrderTable />}
            {selectedOption === 'Add-purchase-return' && <PurchaseReturn />}
            {selectedOption === 'manage-purchase-return' && <PurchaseReturnTable />}
            {selectedOption === 'Add-debit-note' && <div>Add Debit Note Form</div>}
            {selectedOption === 'manage-debit-note' && <div>Search and Manage Debit Note</div>}
            {selectedOption === 'Add-credit-note' && <div>Add Credit Note Form</div>}
            {selectedOption === 'manage-credit-note' && <div>Search and Manage Credit Note</div>}
            {selectedOption === 'Add-supplier' && <SupplierForm />}
            {selectedOption === 'manage-supplier' && <SupplierList />}
          </div>
        </div>
      )}

      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          padding: 10px;
          align-items: stretch;
        }

        .favorites-content {
          font-family: 'Poppins', sans-serif;
          width: 30%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .option-item {
          position: relative;
          padding: 12px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .option-item:hover {
          background-color: #f0f0f0;
        }

        .arrow-icon {
          font-size: 16px;
          color: #888;
        }

        .popup-options {
          position: absolute;
          top: 0;
          left: 100%;
          margin-left: 10px;
          background-color: #fff;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          padding: 10px;
          z-index: 10;
        }

        .popup-option-item {
          padding: 8px 12px;
          cursor: pointer;
          transition: background-color 0.2s;
          white-space: nowrap;
        }

        .popup-option-item:hover {
          background-color: #e0e0e0;
        }

        .output-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .title-bar {
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-left:5px;
          
          
        }

        .back-icon {
          cursor: pointer;
          font-size: 24px;
        }

        .output-content {
          flex-grow: 1;
          padding: 10px;
        }

        /* Media queries for responsive design */
        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .favorites-content,
          .output-content {
            width: 100%;
            height: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default Purchase;
