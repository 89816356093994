import React, { useState } from 'react';
import { TextField, Button, FormControlLabel, Checkbox, Typography, MenuItem, Snackbar, Alert } from '@mui/material';

const AddProduct = ({ units = [], brands = [], onSave = () => {} }) => {
  const [formState, setFormState] = useState({
    group: '', brand: '', productName: '', productCode: '', purchasePrice: '',
    salePrice: '', minSalePrice: '', mrp: '', unit: '', openingStock: '',
    openingStockValue: '', hsnCode: '', cess: '', cgst: '', sgst: '', igst: '',
    saleDiscount: '', lowLevelLimit: '', selectGeneral: '', serialNo: '',
    printDescription: false, oneClickSale: false, enableTracking: false,
    printSerialNo: false, notForSale: false,
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormState({
      ...formState,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = () => {
    onSave(formState);
    setSnackbarMessage('Product added successfully!');
    setOpenSnackbar(true);
    setFormState({
      group: '', brand: '', productName: '', productCodeCode: '', purchasePrice: '',
      salePrice: '', minSalePrice: '', mrp: '', unit: '', openingStock: '',
      openingStockValue: '', hsnCode: '', cess: '', cgst: '', sgst: '', igst: '',
      saleDiscount: '', lowLevelLimit: '', selectGeneral: '', serialNo: '',
      printDescription: false, oneClickSale: false, enableTracking: false,
      printSerialNo: false, notForSale: false,
    });
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={styles.container}>
      <Typography variant="h5" style={styles.title}> Add New Product</Typography>
      <div style={styles.gridContainer}>
        <div style={styles.row}>
          <div style={styles.column}>
            <fieldset style={styles.fieldset}>
              <legend style={styles.legend}>Product Details</legend>
              <div style={styles.formContainer}>
                <div style={styles.formRow}>
                <TextField fullWidth label="Group" name="group" value={formState.group} onChange={handleChange} select required style={styles.textField}>
                    <MenuItem value="food">Food</MenuItem>
                    <MenuItem value="cloth">Cloth</MenuItem>
                    <MenuItem value="electronics">Electronics</MenuItem>
                  </TextField>
                  <TextField fullWidth label="Brand" name="brand" value={formState.brand}  onChange={handleChange} select required style={styles.textField}>
                    <MenuItem value="oil">Oil</MenuItem>
                    <MenuItem value="avasa">Avasa</MenuItem>
                    <MenuItem value="Lg">LG</MenuItem>
                  </TextField>
                </div>
                <div style={styles.formRow}>
                  <TextField fullWidth label="Product Name" name='productName'required style={styles.textField} />
                  <TextField fullWidth label="Product Code" name='productCode' style={styles.textField} />
                </div>
              </div>
            </fieldset>
          </div>

          <div style={styles.column}>
            <fieldset style={styles.fieldset}>
              <legend style={styles.legend}>Price Details</legend>
              <div style={styles.priceformContainer}>
                <div style={styles.formRow}>
                  <TextField fullWidth label="Purchase Price" name='purchasePrice:' required style={styles.textField} />
                  <TextField fullWidth label="Sale Price" name=' salePrice' required style={styles.textField} />
                </div>
                <div style={styles.formRow}>
                  <TextField fullWidth label="Min. Sale Price" name='minSalePrice' style={styles.textField}/>
                  <TextField fullWidth label="M.R.P." name='mrp' style={styles.textField} />
                </div>
              </div>
            </fieldset>
          </div>

          <div style={styles.column}>
            <fieldset style={styles.fieldset}>
              <legend style={styles.legend}>Stock and Unit Details</legend>
              <div style={styles.formContainer}>
                <div style={styles.formRow}>
                <TextField  fullWidth label="Unit" name="unit" value={formState.unit} onChange={handleChange} select required style={styles.textField}>
                    <MenuItem value="Ltr">Ltr</MenuItem>
                    <MenuItem value="Pcs">Pcs</MenuItem>
                    <MenuItem value="Box">Box</MenuItem>
                  </TextField>
                  <TextField fullWidth label="Opening Stock" name='openingStock' style={styles.textField} />
                </div>
                <TextField fullWidth label="Opening Stock Value" name='openingStockValue' style={styles.fullWidthTextField} />
              </div>
            </fieldset>
          </div>
        </div>

        <div style={styles.row}>
          <div style={styles.column}>
            <fieldset style={styles.fieldset}>
              <legend style={styles.legend}>GST Details</legend>
              <div style={styles.gstFormContainer}>
                <div style={styles.gstTopRow}>
                  <TextField fullWidth label="HSN / SAC Code" name='hsnCode' style={styles.textField} />
                  <TextField fullWidth label="Cess %" name='cess' type="number" style={styles.gstCessField} />
                </div>
                <div style={styles.gstDetailRow}>
                  <TextField fullWidth label="CGST %" name='cgst' type="number" style={styles.gstDetailField} />
                  <TextField fullWidth label="SGST %" name='sgst' type="number" style={styles.gstDetailField} />
                  <TextField fullWidth label="IGST %" name='igst' type="number" style={styles.gstDetailField} />
                </div>
              </div>
            </fieldset>
          </div>

          <div style={styles.column}>
            <fieldset style={styles.fieldset}>
              <legend style={styles.legend}>Other Details</legend>
              <div style={styles.formContainer}>
                <div style={styles.formRow}>
                  <TextField fullWidth label="Sale Discount %" name='saleDiscount' style={styles.textField} />
                  <TextField fullWidth label="Low Level Limit" name='lowLevelLimit' style={styles.textField} />
                </div>
                <div style={styles.formRow}>
                <TextField fullWidt label="Select General" name="selectGeneral" value={formState.selectGeneral} onChange={handleChange} select style={styles.textField}>
                    <MenuItem value="General">General</MenuItem>
                    <MenuItem value="Apparal">Apparal</MenuItem>
                  </TextField>
                  <TextField fullWidth label="Serial No" name='serialNo' style={styles.textField} />
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div style={styles.row}>
          <div style={styles.descriptionColumn}>
            <fieldset style={styles.fieldset}>
              <legend style={styles.legend}>Product Description</legend>
              <TextField fullWidth multiline rows={3} label="Description" style={styles.fullWidthTextField} />
            </fieldset>
          </div>

          <div style={styles.settingsColumn}>
            <fieldset style={styles.fieldset}>
              <legend style={styles.legend}>Product Settings</legend>
              <div style={styles.settingsContainer}>
                <FormControlLabel control={
                    <Checkbox
                      name="printDescription"
                      checked={formState.printDescription}
                      onChange={handleChange}
                    />
                  }
                  label="Print Description"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="oneClickSale"
                      checked={formState.oneClickSale}
                      onChange={handleChange}
                    />
                  }
                  label="One Click Sale"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enableTracking"
                      checked={formState.enableTracking}
                      onChange={handleChange}
                    />
                  }
                  label="Enable Tracking"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="printSerialNo"
                      checked={formState.printSerialNo}
                      onChange={handleChange}
                    />
                  }
                  label="Print Serial No."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notForSale"
                      checked={formState.notForSale}
                      onChange={handleChange}
                    />
                  }
                  label="Not for Sale"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </div>

        <div style={styles.buttonRow}>
         <div style={styles.buttonContainer}>
         <Button variant="contained" color="primary" onClick={handleSave} style={styles.saveButton}>Save</Button>
        </div>
       </div>
       <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      </div>
    // </div>
  );
};


const styles = {
  container: {
    padding: '5px 20px',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    fontFamily: 'Poppins',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  title: {
    textAlign: 'center',
    color: 'black',
    marginBottom:'10px',
    marginTop:'10px',

  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  column: {
    width: '48%',
  },
  fieldset: {
    border: '1px solid #bfbfbf',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#ffffff',
  },
  legend: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    padding: '0 10px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  textField: {
    width: '48%',
  },
  fullWidthTextField: {
    width: '100%',
  },
  priceformContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  gstFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  gstTopRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  gstCessField: {
    width: '48%',
  },
  gstDetailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  gstDetailField: {
    width: '31%',
  },
  descriptionColumn: {
    width: '60%',
  },
  settingsColumn: {
    width: '38%',
  },
  settingsContainer: {
        // display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop:'-15px',
      },
      settingsItem: {
        flexBasis: '30%',
      },
      buttonRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
      },
      buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      saveButton: {
        width:'150px',
        backgroundColor: '#0075fd',
        borderRadius: '10px',
        boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
      },
};

export default AddProduct;
