import React, { useState, useEffect } from 'react';
// import { states } from '../BusinessProfile/States'; // Importing the states data
// import { units } from '../BusinessProfile/Unit';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
// import SaleInvoice from './SaleInvoice';

const PurchaseReturn= () => {
  // const [invoiceType, setInvoiceType] = useState('GST');
  // const [invoiceNo, setInvoiceNo] = useState('GST-001');
  const [placeOfSupply, setPlaceOfSupply] = useState('');
  const [billTo, setBillTo] = useState('Cash A/c');
  const [contactNo, setContactNo] = useState('');
  const [clientName, setClientName] = useState('');
  const [address, setAddress] = useState('');
  const [clientGstin, setClientGstin] = useState('');
  const [soldBy, setSoldBy] = useState('');
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  const handleOpenInvoice = () => {
    setIsInvoiceOpen(true);
  };


  // Particulars fields
  const [items, setItems] = useState([{
    description: '',
    unit: '',
    quantity: '',
    salePrice: '',
    discount: '',
    tax: '',
    cess: '',
    amount: ''
  }]);

  // Items to be displayed in the table
  const [tableItems, setTableItems] = useState([]);

  // GST percentages options
  const taxOptions = [0, 5, 12, 18, 28];

  // useEffect(() => {
  //   updateInvoiceNo();
  // }, [invoiceType]);

  // const handleInvoiceTypeChange = (e) => {
  //   setInvoiceType(e.target.value);
  // };

  const handleBillToChange = (e) => {
    setBillTo(e.target.value);
    if (e.target.value === 'Cash A/c') {
      setContactNo('');
      setClientName('CASH');
    } else {
      setClientName('');
    }
  };

  // const updateInvoiceNo = () => {
  //   switch (invoiceType) {
  //     case 'GST':
  //       setInvoiceNo('GST-001');
  //       break;
  //     case 'Non GST':
  //       setInvoiceNo('INV-001');
  //       break;
  //     case 'Bill of Supply':
  //       setInvoiceNo('Bill-001');
  //       break;
  //     default:
  //       setInvoiceNo('');
  //   }
  // };

  const calculateAmount = (item) => {
    const salePrice = parseFloat(item.salePrice) || 0;
    const quantity = parseFloat(item.quantity) || 0;
    const discount = parseFloat(item.discount) || 0;
    const tax = parseFloat(item.tax) || 0;
    const cess = parseFloat(item.cess) || 0;

    const amount = (salePrice * quantity) - discount;
    const taxAmount = (amount * tax) / 100;
    const cessAmount = (amount * cess) / 100;

    return amount + taxAmount + cessAmount;
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;

    // Update amount based on changes
    if (['salePrice', 'quantity', 'discount', 'tax', 'cess'].includes(name)) {
      updatedItems[index].amount = calculateAmount(updatedItems[index]);
    }

    setItems(updatedItems);
  };

  const handleAddItem = () => {
    // Add item to the table
    setTableItems([...tableItems, items[0]]);
    
    // Reset item fields
    setItems([{
      description: '',
      unit: '',
      quantity: '',
      salePrice: '',
      discount: '',
      tax: '',
      cess: '',
      amount: ''
    }]);
  };

  // Calculate Sub Total and Total Amount
  const calculateTotals = () => {
    let subTotal = 0;
    let totalAmount = 0;

    tableItems.forEach(item => {
      subTotal += parseFloat(item.amount) || 0;
    });

    // Assuming tax and cess are not added to subTotal
    totalAmount = subTotal;

    return {
      subTotal,
      totalAmount
    };
  };

  const { subTotal, totalAmount } = calculateTotals();

  // Internal styles
  const styles = {
    form: {
      maxWidth: '1500px',
      margin: 'auto',
      padding: '10px 40px',
      boxSizing: 'border-box'
    },
    fieldset: {
      marginBottom: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '15px',
      backgroundColor: '#f9f9f9'
    },
    legend: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      paddingBottom: '10px'
    },
    input: {
      width: '90%',
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc'
    },
    select: {
      width: '100%',
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc'
    },
    readonlyInput: {
      width: '90%',
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      backgroundColor: '#e9ecef'
    },
    radioContainer: {
      display: 'flex',
      gap: '10px'
    },
    radioLabel: {
      display: 'block',
      marginBottom: '5px'
    },
    button: {
      padding: '10px 20px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#28a745',
      color: '#fff',
      cursor: 'pointer'
    },
    flexContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '15px',
      marginBottom: '.2rem'
    },
    flexItem: {
      flex: '1 1 100px'
    },
    itemFieldset: {
      marginTop: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '15px',
      backgroundColor: '#f9f9f9'
    },
    addItemContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px'
    },
    th: {
      border: '1px solid #ddd',
      padding: '10px',
      backgroundColor: '#f4f4f4',
      textAlign: 'left'
    },
    td: {
      border: '1px solid #ddd',
      padding: '10px'
    },
    footerRow: {
      fontWeight: 'bold',
      backgroundColor: '#f4f4f4'
    },
    buttonContainer: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'flex-end',  // Align buttons to the right
      gap: '10px',  // Add space between buttons
    },
    saveButton: {
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    savePrintButton: {
      backgroundColor: '#2196F3',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  return (
    <form style={styles.form}>
      <fieldset style={styles.fieldset}>
        <legend style={styles.legend}>PurchaseReturn  bill Information</legend>
        <div style={styles.flexContainer}>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Return Type:</label>
            <select  style={styles.select}>
              <option value="GST">GST</option>
              <option value="Non GST">Non GST</option>
              <option value="Bill of Supply">Bill of Supply</option>
            </select>
          </div>
          {/* <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Invoice No:</label>
            <input type="text" value={invoiceNo} readOnly style={styles.readonlyInput} />
          </div> */}
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}> Date:</label>
            <input type="date" defaultValue={new Date().toISOString().split('T')[0]} style={styles.input} />
          </div>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Supplier Name:</label>
            <input type="text" value={clientName} onChange={(e) => setClientName(e.target.value)}  style={{ ...styles.input, backgroundColor: '#fff' }} />
          </div>
          
         
        </div>
        <div style={styles.flexContainer}>
        <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Place of Supply:</label>
            <select
              value={placeOfSupply || ''} // Default value to an empty string if placeOfSupply is not set
              onChange={(e) => setPlaceOfSupply(e.target.value)}
              style={styles.select}
            >
              <option value="" disabled>Select Place of Supply</option> {/* Placeholder option */}
              {/* {states.map(state => (
                <option key={state.id} value={state.name}>{state.name}</option>
              ))} */}
            </select>
          </div>  
             <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Return Bill No:</label>
            <input type="text" value={contactNo} onChange={(e) => setContactNo(e.target.value)} disabled={billTo === 'Cash A/c'} style={{ ...styles.input, backgroundColor: billTo === 'Cash A/c' ? '#e9ecef' : '#fff' }} />
          </div>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Purchase Bill No:</label>
            <input type="text" value={contactNo} onChange={(e) => setContactNo(e.target.value)} disabled={billTo === 'Cash A/c'} style={{ ...styles.input, backgroundColor: billTo === 'Cash A/c' ? '#e9ecef' : '#fff' }} />
          </div>
          
          
        </div>
      </fieldset>

      <fieldset style={styles.itemFieldset}>
        <legend style={styles.legend}>Item Details</legend>
        <div style={styles.flexContainer}>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Item Name:</label>
            <input type="text" name="description" value={items[0].description} onChange={(e) => handleItemChange(0, e)} style={styles.input} />
          </div>

          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Unit:</label>
            <select
              name="unit"
              value={items[0].unit || ''} // Default value to an empty string if unit is not set
              onChange={(e) => handleItemChange(0, e)}
              style={styles.select}
            >
              <option value="" disabled>Select Unit</option> {/* Placeholder option */}
              {/* {units.map(unit => (
                <option key={unit} value={unit}>{unit}</option>
              ))} */}
            </select>
          </div>

          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Quantity:</label>
            <input type="number" name="quantity" value={items[0].quantity} onChange={(e) => handleItemChange(0, e)} style={styles.input} />
          </div>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Sale Price.(₹):</label>
            <input type="number" name="salePrice" value={items[0].salePrice} onChange={(e) => handleItemChange(0, e)} style={styles.input} />
          </div>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Discount.(₹):</label>
            <input type="number" name="discount" value={items[0].discount} onChange={(e) => handleItemChange(0, e)} style={styles.input} />
          </div>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Tax (%):</label>
            <select name="tax" value={items[0].tax} onChange={(e) => handleItemChange(0, e)} style={styles.select}>
              {taxOptions.map(option => (
                <option key={option} value={option}>{option}%</option>
              ))}
            </select>
          </div>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Cess (%):</label>
            <input type="number" name="cess" value={items[0].cess} onChange={(e) => handleItemChange(0, e)} style={styles.input} />
          </div>
          <div style={styles.flexItem}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Amount.(₹):</label>
            <input type="text" name="amount" value={items[0].amount} readOnly style={styles.readonlyInput} />
          </div>
        </div>
        <div style={styles.addItemContainer}>
          <button type="button"  style={styles.button}>Add Item</button>
        </div>
      </fieldset>

         {/* Table to display added items */}
         
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Serial No.</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Description</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Unit</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Quantity</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Sale Price</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Discount</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Tax</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Cess</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {tableItems.length === 0 ? (
            <tr>
              <td colSpan="9" style={{ textAlign: 'center', padding: '10px' }}>No items added</td>
            </tr>
          ) : (
            tableItems.map((item, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.description}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.unit}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.quantity}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>₹{item.salePrice}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>₹{item.discount}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.tax}%</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.cess}%</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.amount}</td>
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <td colSpan="8" style={{ textAlign: 'right', padding: '10px' }}>Sub Total:</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{subTotal.toFixed(2)}</td>
          </tr>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <td colSpan="8" style={{ textAlign: 'right', padding: '10px' }}>Total Amount:</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{totalAmount.toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>
        {/* Save and Save & Print Buttons */}
        <div style={styles.buttonContainer}>
          <button type="button" style={styles.saveButton} >Save</button>
          <button type="button" style={styles.savePrintButton}>Save and Print</button>
        </div>

      {/* Service Form Dialog */}
      <Dialog open={isInvoiceOpen} onClose={() => setIsInvoiceOpen(false)} fullWidth fulHeight maxWidth='md'>
        <DialogContent>
          {/* <SaleInvoice onClose={() => setIsInvoiceOpen(false)} /> */}
        </DialogContent>
      </Dialog>

      </form>
  );
};

export default PurchaseReturn;