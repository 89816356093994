import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomerList from './CustomerList';
import CustomerForm from './CustomerForm';

const Customer = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  return (
    <div className="container">
      {!selectedOption ? (
        <div className="favorites-content">
          <div className="options">
            <div className="option-item" onClick={() => handleOptionClick('Add-client')}>
              <span>Add Customers</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            <div className="option-item" onClick={() => handleOptionClick('manage-customer')}>
              <span>Search and manage cutomer</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
          
          </div>
        </div>
      ) : (
        <div className="output-container">
          <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
          <div className="output-content">
           
            {selectedOption === 'Add-client' && <CustomerForm />}
            {selectedOption === 'manage-customer' && <CustomerList />}
           
          </div>
        </div>
      )}

      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          padding: 10px;
          align-items: stretch;
        }

        .favorites-content {
          font-family: 'Poppins', sans-serif;
          width: 30%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        .sale-invoice {
          padding: 15px;
          background-color: #ffffff;
          margin-bottom: 20px;
        }

        .sale-invoice-header {
          font-size: 18px;
          margin-bottom: 10px;
          color: #333;
        }

        .add-new-sale {
          padding: 8px 16px;
          color: #fff;
          background-color: #007bff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-family: 'Poppins', sans-serif;
          transition: background-color 0.2s;
        }

        .add-new-sale:hover {
          background-color: #0056b3;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
         
        }

        .option-item {
          padding: 12px;
          background-color: #ffffff;
          border-radius: 10px;
          box-shadow: 2px -2px 10px rgba(150, 150, 150, 0.3), inset -3px -3px 18px rgba(197, 197, 197, 0.3), inset -3px -3px 18px rgba(238, 238, 238, 0.6);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
          flex: 1;
        }

        .option-item:hover {
          background-color: #f0f0f0;
        }

        .arrow-icon {
          font-size: 16px;
          color: #888;
        }

        .output-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .back-icon {
          margin: 10px;
          cursor: pointer;
          font-size: 24px;
        }

        .output-content {
          flex-grow: 1;
          padding: 10px;
        }

        /* Media queries for responsive design */
        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .favorites-content,
          .output-content {
            width: 100%;
            height: auto;
          }
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          .favorites-content {
            width: 40%;
          }

          .output-content {
            width: 60%;
          }
        }
      `}</style>
    </div>
  );
};

export default Customer;
