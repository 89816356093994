import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { Add, Edit, Delete, Save, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import StaffForm from './StaffForm';

// Styled TextField with bottom border and search icon
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderBottom: '1px solid',
    },
    '&.Mui-focused fieldset': {
      borderBottom: '2px solid',
    },
  },
  '& .MuiInputAdornment-root': {
    marginLeft: -10,
  },
}));

const StaffList = () => {
  const [staffList, setStaffList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedStaff, setEditedStaff] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch staff from localStorage
    const storedStaff = JSON.parse(localStorage.getItem('staff')) || [];
    setStaffList(storedStaff);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAdd = () => {
    setIsFormOpen(true); // Open the form modal
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedStaff({ ...staffList[index] });
  };

  const handleSave = () => {
    const updatedStaffList = [...staffList];
    updatedStaffList[editIndex] = editedStaff;
    setStaffList(updatedStaffList);
    localStorage.setItem('staff', JSON.stringify(updatedStaffList));
    setEditIndex(null); // Exit edit mode
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedStaff((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDialog(false);
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const updatedStaffList = staffList.filter((_, i) => i !== deleteIndex);
      setStaffList(updatedStaffList);
      localStorage.setItem('staff', JSON.stringify(updatedStaffList));
    }
    handleDeleteClose();
  };

  const filteredStaffList = staffList.filter(
    (staff) =>
      staff.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      staff.mobileNo.includes(searchTerm)
  );

  return (
    <Box sx={{ padding: '10px',width:'1100px', marginLeft:'10px'}}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <StyledTextField
          label="Search Staff"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
        />
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAdd}>
          Add Staff
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ height: '560px', overflowY: 'auto', scrollbarWidth: 'thin',boxShadow:'10' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Serial No</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Address</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Contact No</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Designation</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Department</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Status</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStaffList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  There is no data available
                </TableCell>
              </TableRow>
            ) : (
              filteredStaffList.map((staff, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="fullName"
                        value={editedStaff.fullName}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      staff.fullName
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="address"
                        value={editedStaff.address}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      staff.address
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="mobileNo"
                        value={editedStaff.mobileNo}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      staff.mobileNo
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="designation"
                        value={editedStaff.designation}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      staff.designation
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="department"
                        value={editedStaff.department}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      staff.department
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="status"
                        value={editedStaff.status}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      staff.status || 'Active'
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <IconButton color="primary" onClick={handleSave}>
                        <Save />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton  style={{color:'blue'}}  onClick={() => handleEdit(index)}>
                          <Edit />
                        </IconButton>
                        <IconButton style={{color:'red'}} onClick={() => handleDeleteOpen(index)}>
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={openDialog}
        onClose={handleDeleteClose}
        aria-labelledby="delete-confirmation-dialog"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this staff member?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* StaffForm Modal */}
      <Dialog
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="staff-form-dialog"
      >
        <DialogTitle id="staff-form-dialog" style={{fontWeight: '700'}}>Add Staff</DialogTitle>
        <DialogContent>
          <StaffForm closeForm={() => setIsFormOpen(false)} /> {/* Pass a function to close the form */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StaffList;