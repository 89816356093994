import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, RadioGroup, FormControlLabel, Radio, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { states } from './States';
import i1 from '../assets/bma.jpg';

const Business = () => {
  const [businessName, setBusinessName] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [gstStatus, setGstStatus] = useState('Registered - Regular');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate('/login')
  }
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '85%', md: '75%' },
        maxWidth: '900px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        margin: 'auto',
        mt: 4,
        height: 'auto', // Ensure the container adjusts its height
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center', // Center image vertically
          justifyContent: 'center', // Center image horizontally
          overflow: 'hidden',
        }}
      >
        <img src={i1} alt="Business" style={{ width: '500px', height: '450px', objectFit: 'cover' }} />
      </Box>

      {/* Form Section */}
      <Box sx={{ flex: 1, padding: { xs: '10px', md: '30px' }, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>Complete Your Business Profile</Typography>
        
        <TextField
          fullWidth
          label="Business Name"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          sx={{ marginBottom: '15px', maxWidth: '400px' }} // Adjusted size
          required
        />

        {/* Username Field */}
        <TextField
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ marginBottom: '15px', maxWidth: '400px' }} // Adjusted size
          required
        />

        {/* Password Field */}
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: '15px', maxWidth: '400px' }} // Adjusted size
          required
        />

        <FormControl fullWidth required sx={{ marginBottom: '15px', maxWidth: '400px' }}>
          <InputLabel id="state-label">State</InputLabel>
          <Select
            labelId="state-label"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            {states.map((state) => (
              <MenuItem key={state.id} value={state.name}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Typography sx={{ marginBottom: '15px' }}>GST Registration Status</Typography>
        <RadioGroup
          value={gstStatus}
          onChange={(e) => setGstStatus(e.target.value)}
          sx={{ marginBottom: '20px' }}
          required
        >
          <FormControlLabel value="Registered - Regular" control={<Radio required />} label="Registered - Regular" />
          <FormControlLabel value="Registered - Composite" control={<Radio required />} label="Registered - Composite" />
          <FormControlLabel value="Unregistered" control={<Radio required />} label="Unregistered" />
        </RadioGroup>

        <Button sx={{ backgroundColor: "black", color: "white" }} onClick={handleSubmit}>
          Register
        </Button>
      </Box>
    </Box>
  );
};

export default Business;
