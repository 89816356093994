import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Select, MenuItem, Button, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Paper, FormControl, InputLabel, CircularProgress
} from '@mui/material';
import { CalendarToday, PictureAsPdf, Print, FilterList, GetApp } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';

const ItemLedger = () => {
    const [selectedItem, setSelectedItem] = useState('Sample Item');
    const [dateRange, setDateRange] = useState('Period: 01-Aug-2024 To 31-Aug-2024');
    const [selectedDate, setSelectedDate] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to simulate data fetching based on selected item
    const fetchTableData = (item) => {
        setLoading(true);
        // Simulating a backend call with setTimeout
        setTimeout(() => {
            const simulatedData = [
                {
                    date: '01-Aug-2024',
                    voucher: `Voucher-${item}-01`,
                    party: `Party-${item}-01`,
                    inward: 100,
                    outward: 50,
                    balance: 50,
                },
                {
                    date: '02-Aug-2024',
                    voucher: `Voucher-${item}-02`,
                    party: `Party-${item}-02`,
                    inward: 200,
                    outward: 100,
                    balance: 150,
                },
                // Add more rows as needed
            ];
            setTableData(simulatedData);
            setLoading(false);
        }, 1000); // Simulate 1-second delay
    };

    useEffect(() => {
        fetchTableData(selectedItem);
    }, [selectedItem]);

    const handleExportExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(tableData);
        XLSX.utils.book_append_sheet(wb, ws, 'Item Ledger');
        XLSX.writeFile(wb, 'ItemLedger.xlsx');
    };

    const handleExportPdf = () => {
        const doc = new jsPDF();
        doc.text('Item Ledger', 20, 20);
        doc.save('ItemLedger.pdf');
    };

    const handleDateChange = (value) => {
        setSelectedDate(value);
        setDateRange(`Period: ${value.format('DD-MMM-YYYY')} To ${value.format('DD-MMM-YYYY')}`);
    };

    return (
        <Box sx={{
            padding: 2,
            background: '#f0f0f3',
            boxShadow: '9px 9px 16px #d9d9db, -9px -9px 16px #ffffff',
            borderRadius: '15px',
            width: '95%',
            margin: 'auto',
            marginTop: '20px'
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Item Ledger</Typography>
                <Box>
                    <Button variant="contained" onClick={handleExportExcel} sx={{ marginRight: 2 }}>
                        <GetApp /> Export Excel
                    </Button>
                    <Button variant="contained" onClick={handleExportPdf} sx={{ marginRight: 2 }}>
                        <PictureAsPdf /> Export PDF
                    </Button>
                    <IconButton>
                        <Print />
                    </IconButton>
                    <IconButton>
                        <FilterList />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Item</InputLabel>
                    <Select
                        value={selectedItem}
                        onChange={(e) => setSelectedItem(e.target.value)}
                        label="Item"
                    >
                        <MenuItem value="Sample Item">Sample Item</MenuItem>
                        <MenuItem value="Phone">Phone</MenuItem>
                        <MenuItem value="Laptop">Laptop</MenuItem>
                        <MenuItem value="Tablet">Tablet</MenuItem>
                        {/* Add more items as needed */}
                    </Select>
                </FormControl>
                <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: 2 }}>{dateRange}</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year', 'month', 'day']}
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <IconButton {...params}><CalendarToday /></IconButton>}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <CircularProgress />
                </Box>
            ) : tableData.length > 0 ? (
                <Table component={Paper} sx={{ marginTop: 3 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Voucher</TableCell>
                            <TableCell>Party</TableCell>
                            <TableCell>Inward</TableCell>
                            <TableCell>Outward</TableCell>
                            <TableCell>Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.voucher}</TableCell>
                                <TableCell>{row.party}</TableCell>
                                <TableCell>{row.inward}</TableCell>
                                <TableCell>{row.outward}</TableCell>
                                <TableCell>{row.balance}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                    <Typography>No data available</Typography>
                </Box>
            )}
        </Box>
    );
};

export default ItemLedger;
