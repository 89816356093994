import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Box, Typography } from '@mui/material';

const defaultData = [
  {
    sNo: '1',
    orderType: 'Type A',
    placeOfSupply: 'Location X',
    supplierName: 'Supplier Y',
    contactNo: '1234567890',
    items: 'Item 1, Item 2',
    newItem: 'New Item 1',
    paymentMode: 'Credit',
    subtotal: '1000',
    total: '1200',
    itemName: 'Item 1',
    quantity: '10',
    unit: 'pcs',
    price: '100',
    discount: '10',
    tax: '5',
    cess: '2',
    amount: '85'
  },
  // Add more default data as needed
];

const PurchaseOrderTable = () => {
  const [tableData, setTableData] = useState(defaultData);
  const [searchTerm, setSearchTerm] = useState('');

  // Handle search
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filtered data based on search term
  const filteredData = tableData.filter(item =>
    item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.unit.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 ,width:'1000px'}}>
      <Typography variant="h6" gutterBottom>
        Purchase Orders
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: '250px' }}
        />
        <Button variant="contained" color="primary" href="/add-new-order">
          Add New Purchase Order
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ Width: '100%', overflowX: 'auto' }}>
        <Table sx={{ minWidth: '800' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>S. No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Order Type</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Place Of Supply</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Supplier Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Contact No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Items</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Payment Mode</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Item Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Quantity</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Unit</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Price</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Discount</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Tax</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Cess</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Subtotal</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.sNo}</TableCell>
                  <TableCell>{item.orderType}</TableCell>
                  <TableCell>{item.placeOfSupply}</TableCell>
                  <TableCell>{item.supplierName}</TableCell>
                  <TableCell>{item.contactNo}</TableCell>
                  <TableCell>{item.items}</TableCell>
                  <TableCell>{item.paymentMode}</TableCell>
                  <TableCell>{item.itemName}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.unit}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>{item.discount}</TableCell>
                  <TableCell>{item.tax}</TableCell>
                  <TableCell>{item.cess}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.subtotal}</TableCell>
                  <TableCell>{item.total}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={16} align="center">
                  No matching records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PurchaseOrderTable;
