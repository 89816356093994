import React, { useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Add, Edit, Delete, Save, Search, Cancel } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
// import ServiceForm from './ServiceForm'; // Import the service form component

// Styled TextField with bottom border and search icon
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderBottom: '1px solid',
    },
    '&.Mui-focused fieldset': {
      borderBottom: '2px solid',
    },
  },
  '& .MuiInputAdornment-root': {
    marginLeft: -10,
  },
}));

const SaleList = () => {
  const [services, setServices] = useState([
    { status: 'Paid', type: 'GST', invoiceNo: 'GST-001', date: '2024-08-01', contactNo: '1234567890', clientName: 'Client A', address: 'Address A', state: 'Tamil Nadu', gstin: 'GSTIN123456', createdOn: '2024-08-01' },
    { status: 'UnPaid', type: 'Non GST', invoiceNo: 'INV-001', date: '2024-08-02', contactNo: '0987654321', clientName: 'Client B', address: 'Address B', state: 'Tamil Nadu', gstin: 'GSTIN654321', createdOn: '2024-08-02' },
    { status: 'Paid', type: 'Non GST', invoiceNo: 'INV-002', date: '2024-08-02', contactNo: '0987654321', clientName: 'Client C', address: 'Address C', state: 'Tamil Nadu', gstin: 'GSTIN654321', createdOn: '2024-08-02' },
    { status: 'UnPaid', type: 'Non GST', invoiceNo: 'INV-003', date: '2024-08-02', contactNo: '0987654321', clientName: 'Client D', address: 'Address D', state: 'Tamil Nadu', gstin: 'GSTIN654321', createdOn: '2024-08-02' },
    // Add more dummy data as needed
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedService, setEditedService] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddService = () => {
    setIsFormOpen(true);
  };

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const updatedServices = services.filter((_, i) => i !== deleteIndex);
      setServices(updatedServices);
    }
    handleDeleteClose();
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedService({ ...services[index] });
  };

  const handleSave = () => {
    const updatedServices = [...services];
    updatedServices[editIndex] = editedService;
    setServices(updatedServices);
    setEditIndex(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredServices = services.filter(
    (service) =>
      service.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: '2rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <StyledTextField
          label="Search Service"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
        />
        <Button variant="contained" color="primary" startIcon={<Add />} >
          Add Service
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ height: '560px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
        <Table sx={{ minWidth: 1600 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>S.No</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Status</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Type</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Invoice No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Date</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Contact No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Client Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Address</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>State</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>GSTIN</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Created On</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredServices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  There is no data available
                </TableCell>
              </TableRow>
            ) : (
              filteredServices.map((service, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{service.status}</TableCell>
                  <TableCell>{service.type}</TableCell>
                  <TableCell>{service.invoiceNo}</TableCell>
                  <TableCell>{service.date}</TableCell>
                  <TableCell>{service.contactNo}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="clientName"
                        value={editedService.clientName}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.clientName
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="address"
                        value={editedService.address}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.address
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="state"
                        value={editedService.state}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.state
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="gstin"
                        value={editedService.gstin}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.gstin
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="createdOn"
                        value={editedService.createdOn}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      service.createdOn
                    )}
                  </TableCell>
                  <TableCell sx={{display: 'flex'}}>
                    {editIndex === index ? (
                      <>
                        <Button onClick={handleSave} color="primary">
                          <Save />
                        </Button>
                        <IconButton onClick={() => setEditIndex(null)}>
                          <Cancel />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton onClick={() => handleEdit(index)} color="primary">
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteOpen(index)} color="error">
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this service?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Service Form Dialog */}
      {/* <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)}>
        <DialogTitle>Add/Edit Service</DialogTitle>
        <DialogContent>
          <ServiceForm
            onSave={(newService) => {
              if (editIndex !== null) {
                const updatedServices = [...services];
                updatedServices[editIndex] = newService;
                setServices(updatedServices);
                setEditIndex(null);
              } else {
                setServices([...services, newService]);
              }
              setIsFormOpen(false);
            }}
            service={editIndex !== null ? editedService : {}}
          />
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

export default SaleList;
