import React, { useState } from 'react';
import {
  Container, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Box
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { SaveAlt as ExportExcelIcon, PictureAsPdf as ExportPDFIcon } from '@mui/icons-material';

// Claymorphism style
const claymorphismStyle = {
  boxShadow: '9px 9px 16px rgba(0, 0, 0, 0.2), -9px -9px 16px rgba(255, 255, 255, 0.8)',
  background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
  borderRadius: '20px',
  padding: '20px',
};

// Sample Data
const sampleData = [
  { productName: 'phone', qty: 10, unit: 'Pcs', value: 500, category: 'Electronics', brand: 'Brand A' },
  // More sample data can be added here
];

const ItemWisePurchase = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  // Function to format the date to MM/DD/YY
  const formatDate = (date) => dayjs(date).format('MM/DD/YY');

  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ItemWisePurchase');
    XLSX.writeFile(workbook, 'ItemWisePurchase.xlsx');
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Item Wise Purchase Report', 20, 20);
    sampleData.forEach((item, index) => {
      doc.text(`${item.productName} - Qty: ${item.qty}, Value: ${item.value}`, 20, 30 + index * 10);
    });
    doc.save('ItemWisePurchase.pdf');
  };

  return (
    <Container>
      <Paper style={claymorphismStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" gutterBottom>
            Itemwise Purchase
          </Typography>
          <Box display="flex" justifyContent="flex-end" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
            <Button variant="contained" onClick={exportExcel} startIcon={<ExportExcelIcon />} sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}>
              Export Excel
            </Button>
            <Button variant="contained" onClick={exportPDF} startIcon={<ExportPDFIcon />}>
              Export PDF
            </Button>
          </Box>
        </Box>

        <Typography variant="body1" gutterBottom>
          Period: {formatDate(selectedDate)} to {formatDate(selectedDate)}
        </Typography>

        {/* Date Picker */}
        <Box display="flex" justifyContent="flex-end" mb={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year', 'month', 'day']}  // Displaying day, month, and year
              label="Select Date"
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              format="MM/DD/YY"
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>
        </Box>

        {/* Table */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Brand</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sampleData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.productName}</TableCell>
                <TableCell>{row.qty}</TableCell>
                <TableCell>{row.unit}</TableCell>
                <TableCell>{row.value}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.brand}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default ItemWisePurchase;
