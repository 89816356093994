import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, IconButton, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { SaveAlt, PictureAsPdf } from '@mui/icons-material';

const clayMorphismStyle = {
  boxShadow: '9px 9px 16px #d9d9d9, -9px -9px 16px #ffffff',
  borderRadius: '15px',
  padding: '20px',
  backgroundColor: '#f0f0f0',
};

const StockSummary = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [date, setDate] = useState(dayjs());

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet([selectedProduct]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Stock Summary');
    XLSX.writeFile(workbook, 'StockSummary.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Stock Summary', 20, 20);
    doc.text(JSON.stringify(selectedProduct), 20, 30);
    doc.save('StockSummary.pdf');
  };

  const products = [
    { name: 'phone', category: 'Electronics', brand: 'XYZ', qty: 1, unit: 'Pcs', rate: 1000, value: 1000 },
    // Add more products as needed
  ];

  return (
    <Box style={clayMorphismStyle}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Stock Summary</Typography>
        <Box>
          <Button
            onClick={exportToExcel}
            variant="contained"
            startIcon={<SaveAlt />}
            sx={{ mr: 2 }}
          >
            Export Excel
          </Button>
          <Button
            onClick={exportToPDF}
            variant="contained"
            startIcon={<PictureAsPdf />}
          >
            Export PDF
          </Button>
        </Box>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Select Date"
          value={date}
          onChange={(newValue) => setDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      {!selectedProduct && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index} onClick={() => handleProductClick(product)}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.category}</TableCell>
                <TableCell>{product.brand}</TableCell>
                <TableCell>{product.qty}</TableCell>
                <TableCell>{product.unit}</TableCell>
                <TableCell>{product.rate}</TableCell>
                <TableCell>{product.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {selectedProduct && (
        <Box mt={2}>
          <Select
            value={selectedProduct.name}
            onChange={(e) => {
              const product = products.find(p => p.name === e.target.value);
              setSelectedProduct(product);
            }}
            displayEmpty
            fullWidth
          >
            {products.map((product, index) => (
              <MenuItem key={index} value={product.name}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
          <Table mt={2}>
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Voucher</TableCell>
                <TableCell>Party</TableCell>
                <TableCell>Inward</TableCell>
                <TableCell>Outward</TableCell>
                <TableCell>Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Render the ledger details here based on selectedProduct */}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default StockSummary;
