import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ServiceForm from './ServiceForm';
import ServiceList from './ServiceList';

const Service = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  // Map of titles based on the selected option
  const titles = {
    'add-service': 'Add Service',
    'manage-service': 'Search and Manage Service',
  };

  return (
    <div className="container">
      {!selectedOption ? (
        <div className="service-content">
          <div className="options">
            <div className="option-item" onClick={() => handleOptionClick('add-service')}>
              <span>Add Service</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
            <div className="option-item" onClick={() => handleOptionClick('manage-service')}>
              <span>Search and Manage Service</span> <ArrowForwardIosIcon className="arrow-icon" />
            </div>
          </div>
        </div>
      ) : (
        <div className="output-container">
          <div className="output-header">
            <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
            <h2 className="output-title">{titles[selectedOption]}</h2>
          </div>
          <div className="output-content" style={{marginLeft:'100px'}}>
            {selectedOption === 'add-service' && <ServiceForm />}
            {selectedOption === 'manage-service' && <ServiceList />}
          </div>
        </div>
      )}

      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          padding: 10px;
          align-items: stretch;
        }

        .service-content {
          font-family: 'Poppins', sans-serif;
          width: 30%;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .option-item {
          padding: 12px;
          background-color: #ffffff;
          border-radius: 10px;
         box-shadow: 2px -2px 10px rgba(150, 150, 150, 0.3), inset -3px -3px 18px rgba(197, 197, 197, 0.3), inset -3px -3px 18px rgba(238, 238, 238, 0.6);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .option-item:hover {
          background-color: #f0f0f0;
        }

        .arrow-icon {
          font-size: 16px;
          color: #888;
        }

        .output-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .output-header {
          display: flex;
          align-items: center;
          margin: 10px;
        }

        .back-icon {
          cursor: pointer;
          font-size: 24px;
        }

        .output-title {
          margin-left: 10px;
          font-size: 18px;
          font-weight: 600;
          font-family: 'Poppins', sans-serif;
        }

        .output-content {
          flex-grow: 1;
          padding: 10px;
        }

        /* Media queries for responsive design */
        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .service-content,
          .output-content {
            width: 100%;
            height: auto;
          }
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          .service-content {
            width: 40%;
          }

          .output-content {
            width: 60%;
          }
        }

        @media (min-width: 1025px) {
          .service-content {
            width: 25%;
          }

          .output-content {
            width: 75%;
          }
        }

        @media (min-width: 2560px) {
          .service-content {
            width: 20%;
          }

          .output-content {
            width: 80%;
          }
        }
      `}</style>
    </div>
  );
};

export default Service;
