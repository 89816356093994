import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Add, Edit as EditIcon, Delete as DeleteIcon, Save, Search, Cancel } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import SupplierForm from './SupplierForm'; // Import SupplierForm component

// Styled TextField with bottom border and search icon
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderBottom: '1px solid',
    },
    '&.Mui-focused fieldset': {
      borderBottom: '2px solid',
    },
  },
  '& .MuiInputAdornment-root': {
    marginLeft: -10,
  },
}));

const SupplierList = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedSupplier, setEditedSupplier] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    // Fetch suppliers from localStorage
    const storedSuppliers = JSON.parse(localStorage.getItem('suppliers')) || [];
    setSuppliers(storedSuppliers);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddSupplier = () => {
    setIsFormOpen(true);
  };

  const handleDeleteOpen = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const updatedSuppliers = suppliers.filter((_, i) => i !== deleteIndex);
      setSuppliers(updatedSuppliers);
      localStorage.setItem('suppliers', JSON.stringify(updatedSuppliers));
    }
    handleDeleteClose();
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedSupplier({ ...suppliers[index] });
  };

  const handleSave = () => {
    const updatedSuppliers = [...suppliers];
    updatedSuppliers[editIndex] = editedSupplier;
    setSuppliers(updatedSuppliers);
    localStorage.setItem('suppliers', JSON.stringify(updatedSuppliers));
    setEditIndex(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedSupplier((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredSuppliers = suppliers.filter(
    (supplier) =>
      supplier.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supplier.contactPerson.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: '2rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <StyledTextField
          label="Search Supplier"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
        />
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddSupplier}>
          Add Supplier
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ height: '560px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
        <Table sx={{ minWidth: 1600 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>S.No</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Company Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Contact Person</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Contact No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Address</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>State</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>GSTIN</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Bank Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Bank Account No.</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Opening Balance</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSuppliers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  There is no data available
                </TableCell>
              </TableRow>
            ) : (
              filteredSuppliers.map((supplier, index) => (
                <TableRow key={index}>
                     <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="companyName"
                        value={editedSupplier.companyName}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.companyName
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="contactPerson"
                        value={editedSupplier.contactPerson}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.contactPerson
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="contactNo"
                        value={editedSupplier.contactNo}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.contactNo
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="address"
                        value={editedSupplier.address}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.address
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="state"
                        value={editedSupplier.state}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.state
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="gstin"
                        value={editedSupplier.gstin}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.gstin
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="bankName"
                        value={editedSupplier.bankName}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.bankName
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="bankAccountNo"
                        value={editedSupplier.bankAccountNo}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.bankAccountNo
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <TextField
                        name="openingBalance"
                        value={editedSupplier.openingBalance}
                        onChange={handleEditChange}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      supplier.openingBalance
                    )}
                  </TableCell>
                  <TableCell sx={{display: 'flex'}}>
                    {editIndex === index ? (
                      <>
                        <Button onClick={handleSave} color="primary">
                          <Save />
                        </Button>
                        <IconButton onClick={() => setEditIndex(null)}>
                          <Cancel />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton onClick={() => handleEdit(index)} color="primary">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteOpen(index)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this supplier?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Service Form Dialog */}
      <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)} fullWidth maxWidth='lg'>
        <DialogContent>
          <SupplierForm onClose={() => setIsFormOpen(false)} />
        </DialogContent>
      </Dialog>

      {/* Custom Scrollbar Styling */}
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </Box>
  );
};

export default SupplierList;