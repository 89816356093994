import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AddBrand = () => {
  const [brandName, setBrandName] = useState('');
  const [brands, setBrands] = useState([{ id: 1, name: 'Avasa' }]);
  const [error, setError] = useState(''); // State for error message

  const handleSave = () => {
    if (brandName.trim() === '') {
      setError('Brand Name is required');
      return;
    }

    const newBrand = {
      id: brands.length + 1,
      name: brandName,
    };
    setBrands([...brands, newBrand]);
    setBrandName('');
    setError(''); // Clear error message on successful save
  };

  const handleEdit = (id) => {
    const brandToEdit = brands.find((brand) => brand.id === id);
    if (brandToEdit) {
      setBrandName(brandToEdit.name);
      setBrands(brands.filter((brand) => brand.id !== id));
    }
  };

  const handleDelete = (id) => {
    setBrands(brands.filter((brand) => brand.id !== id));
  };

  return (
    <div style={styles.container}>
      <h2>Brand</h2>
      
      {/* Add Brand Section with Fieldset and Legend */}
      <fieldset style={styles.fieldset}>
        <legend style={styles.legend}>Add Brand</legend>
        <div style={styles.formContainer}>
          <TextField
            label={<span className="required-label">Brand Name *</span>} // Use custom label
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            variant="outlined"
            size="small"
            style={styles.textField}
            error={!!error} // Show error state
            helperText={error} // Display error message
          />
        </div>
        <button onClick={handleSave} style={styles.saveButton}>
          Save
        </button>
      </fieldset>

      {/* Existing Data Section with Fieldset and Legend */}
      <fieldset style={styles.fieldset}>
        <legend style={styles.legend}>Existing Data</legend>
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>S. No.</th>
                <th style={styles.tableHeader}>Brand Name</th>
                <th style={styles.tableHeader}>Action</th>
              </tr>
            </thead>
            <tbody>
              {brands.map((brand, index) => (
                <tr key={brand.id}>
                  <td style={styles.tableCell}>{index + 1}</td>
                  <td style={styles.tableCell}>{brand.name}</td>
                  <td style={styles.tableCell}>
                    <IconButton style={styles.editiconButton} onClick={() => handleEdit(brand.id)} aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton style={styles.deleteiconButton} onClick={() => handleDelete(brand.id)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
};

const styles = {
  container: {
    padding: '5px 20px 5px 20px',
    width: '700px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    fontFamily: 'Poppins',
    margin: '120px 0 0 300px',
  },
  fieldset: {
    border: '1px solid #bfbfbf',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '30px',
    backgroundColor: '#ffffff',
  },
  legend: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    padding: '0 10px',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  textField: {
    width: '550px',
    marginLeft: '10px',
  },
  saveButton: {
    padding: '8px 26px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    marginLeft: '480px',
    backgroundColor: '#0075fd',
    borderRadius: '10px',
    boxShadow: '8px 8px 18px rgba(0, 0, 0, 0.3), inset -12px -12px 18px rgba(0, 0, 0, 0.3), inset 12px 12px 18px rgba(255, 255, 255, 0.6)',
  },
  tableContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    border: '1px solid #ddd',
    padding: '8px',
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
  },
  editiconButton: {
    padding: '4px',
    color: '#05b554',
  },
  deleteiconButton: {
    padding: '4px',
    color: '#f2392c',
  },
};

export default AddBrand;
